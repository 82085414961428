<template>
  <div>
    <flutterwave-pay-button
        :tx_ref="generateReference()"
        :amount="amount"
        currency='NGN'
        payment_options="card,ussd"
        public_key="FLWPUBK_TEST-b42ceac68c20586de511ddc547734bbf-X"
        :customer="customer"
        :callback="makePaymentCallback"
        :onclose="closedPaymentModal"
    >
      <slot></slot>
    </flutterwave-pay-button>
  </div>
</template>

<script>
export default {
  name: "Flutterwave",
  props: {
    amount: {
      type: Number,
      required: true
    },
    customer: {
      type: Object,
      required: true
    }
  },
  methods: {
    makePaymentCallback(response) {
      this.$emit('done', response);
    },
    closedPaymentModal() {
    },
    generateReference() {
      let date = new Date()
      return date.getTime().toString();
    }
  }
}
</script>
