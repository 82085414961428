<template>
  <div v-if="overlay" class="py-14 my-auto w-full">
    <RandomQuestions @done="overlay = ''"/>
    <!--    <template v-if="overlay === 'occupation'">-->
    <!--      <SectionHeader :title="`Are you a ${ getOccupation() }?`"/>-->
    <!--      <div class="flex flex-col gap-3">-->
    <!--        <div @click="completed = true" class="border px-5 py-3 cursor-pointer rounded-lg flex items-center justify-between">-->
    <!--          Yes-->
    <!--          <i class="fa fa-long-arrow-alt-right text-gray-500"></i>-->
    <!--        </div>-->
    <!--        <div @click="completed = true" class="border px-5 py-3 cursor-pointer rounded-lg flex items-center justify-between">-->
    <!--          No-->
    <!--          <i class="fa fa-long-arrow-alt-right text-gray-500"></i>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </template>-->
  </div>

  <div v-else class="py-14 my-auto w-full">
    <template v-if="loading">
      <Loader v-if="loading === 'checking'" text="Checking number.."/>
      <Loader v-else-if="loading === 'confirming'" text="Confirming your NIN.."/>
      <Loader v-else-if="loading === 'hold-up'" text="Crossing ojuelegba bridge, no cars are stopping...pim..pim"/>
      <Loader v-else :text="loading"/>
    </template>

    <template v-else>
      <template v-if="currentView === 'default'">
        <SectionHeader
          title="Alternatively, your NIN will do just fine"
          subtitle="We could find any usage details attached to your phone number, provide your NIN to continue"
        />
        <Message v-if="error" variant="error" class="mb-6">{{ error }}</Message>
        <AppInput type="number" v-model="number" placeholder="Enter NIN number"/>
        <div class="flex mt-12">
          <AppButton @click="submit()" :disabled="!number">Continue</AppButton>
        </div>
        <p class="mt-5 text-gray-500">
          Alternatively, just give us your <span @click="$emit('view', 'no-record')" class="text-blue-700 cursor-pointer">other number</span>
        </p>
        <p @click="$emit('done')" class="mt-2 text-gray-400 cursor-pointer">Skip</p>
      </template>

      <template v-if="currentView === 'verify'">
        <SectionHeader
          :title="'Verify NIN number'"
          :subtitle="`A verification code has been sent to the email address registered with the NIN number ${number}, enter code below to verify`"
        />
        <Message v-if="error" variant="error" class="mb-6">{{ error }}</Message>
        <AppInput v-model="code" placeholder="Enter code"/>
        <div class="flex mt-12">
          <AppButton @click="verifyCode()" :disabled="!code">Continue</AppButton>
        </div>
        <p @click="change()" class="mt-4 text-gray-500 cursor-pointer">Change NIN number</p>
      </template>
    </template>
  </div>
</template>

<script>
	import SectionHeader from "@/components/global/SectionHeader"
	import AppButton from "@/components/global/Button";
	import AppInput from "@/components/global/Input";
	import Message from "@/components/global/Message";
	import Loader from "@/components/global/Loader";
	import { namesMatch } from "@/lib/utils";
	import view from '@/mixins/view';
	import ajax from "@/mixins/ajax";
	import RandomQuestions from "@/components/sections/RandomQuestions";

	export default {
		name: 'NationalIdentityNumber',
		components: { RandomQuestions, Loader, Message, AppInput, AppButton, SectionHeader },
		mixins: [view, ajax],
		data() {
			return {
				number: '',
				code: '',
				currentView: 'default'
			}
		},
		methods: {
			async submit() {
				this.error = '';
				if (!this.number) return;
				this.overlay = 'random-questions';
				this.setLoading('checking');
				setTimeout(() => {
					if (this.loading === 'confirming') this.setLoading('hold-up');
				}, 5000);
				try {
					const res = await this.$store.dispatch('postData', { type: 'nin', nin: this.number });
					if (res.error_code === '01') this.currentView = 'verify';
					else await this.checkData();
				} catch (e) {
					this.setError('An error occurred, please try again');
				}
				this.setLoading('');
			},
			async checkData() {
				this.setLoading('confirming');
				try {
					const { data } = await this.$http.post('/customer/checkdata', { token: this.$store.state.token });
					if (!data.data.has_data) this.setError('We could not get any data with this NIN, please know that this will impact on your eligibility');
					if (namesMatch(this.$store.state.profile.full_name, data.data.name)) return this.next();
					this.setError('Looks like this NIN belongs to someone else, please try again with a number that belongs to you!');
				} catch (e) {
					this.setError('An error occurred, please try again');
				}
				this.setLoading('');
			},
			async verifyCode() {
				this.overlay = 'random-questions';
				this.setLoading('Please wait..');
				try {
					const { data } = await this.$http.post('/customer/validate_email_otp', { otp: this.code, token: this.$store.state.token });
					if (!data.status) return this.setError(data.message);
					await this.checkData();
					this.currentView = 'default';
				} catch (e) {
					this.setError('An error occurred, please try again');
				}
				this.setLoading('');
				this.code = '';
			},
			next() {
				this.$emit('done')
			},
			change() {
				this.currentView = 'default'
			},
			getOccupation() {
				const rand = Math.floor(Math.random() * 10) + 1;
				return ['banker', 'doctor', 'lawyer', 'software developer', 'business owner', 'carpenter', 'nurse', 'laborer', 'administrative assistant', 'mechanic', 'journalist'][rand];
			}
		}
	}
</script>
