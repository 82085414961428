<template>
  <div v-if="overlay && !completed" class="py-14 my-auto w-full">
    <template v-if="overlay === 'salary'">
      <SectionHeader title="Are you a salary earner?"/>
      <div class="flex flex-col gap-3">
        <div @click="completed = true" class="border px-5 py-3 cursor-pointer rounded-lg flex items-center justify-between">
          Yes
          <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
        </div>
        <div @click="completed = true" class="border px-5 py-3 cursor-pointer rounded-lg flex items-center justify-between">
          No
          <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
        </div>
      </div>
    </template>
  </div>
  <div v-else>
    <div v-if="loading" class="my-auto">
      <Loader :text="loading"/>
    </div>
    <div v-else class="my-auto">
      <template v-if="offers.length">
        <p class="mb-6 text-lg text-gray-700">Select an offer below</p>
        <swiper ref="mySwiper" :options="swiperOptions">
          <template v-for="(offer, i) in offers">
            <swiper-slide :key="i">
              <div @click="selectOffer(offer)" class="bg-gray-50 shadow-md border rounded-lg p-6 cursor-pointer">
                <p class="text-gray-600">Pay</p>
                <div class="text-xl font-semibold">{{ offer.upfront | currency }} <small class="text-gray-600">now</small></div>
                <div class="text-gray-700">and {{ offer.monthly_repayment | currency }}/mo for {{ offer.duration }} months</div>
                <div class="flex justify-end items-center mt-52 text-gray-600">
                  <i class="fa fa-user mr-2"></i> {{ offer.lender }}
                </div>
              </div>
            </swiper-slide>
          </template>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>

        <Modal v-if="selectedOffer">
          <div class="my-auto">
            <SectionHeader
                title="Confirm offer"
                :subtitle="`I agree that I will pay ${formatCurrency(selectedOffer.upfront)} now and pay ${formatCurrency(selectedOffer.monthly_repayment)} in ${selectedOffer.duration} ${tenor_type.text}`"
            />
            <div class="flex gap-4 items-center">
              <p class="text-sm">Repayment frequency:</p>
              <div class="flex gap-4 items-center">
                <div
                    v-for="f in frequencies" :key="f.id" @click="changeTenorType(f.id)"
                    class="px-2 py-1 text-sm border-2 rounded-lg bg-gray-100 cursor-pointer"
                    :class="{ 'border-2 border-blue-500 shadow': selectedOffer.tenor_type === f.id }"
                >
                  {{ f.adverb }}
                </div>
              </div>
            </div>
            <div class="flex mt-6">
              <AppButton @click="acceptOffer()" :loading="accepting">Accept</AppButton>
              <button @click="selectedOffer = null" class="ml-2 text-red-500 py-1 px-3">Cancel</button>
            </div>
          </div>
        </Modal>
      </template>
      <template v-else>
        <div class="text-center max-w-xs mx-auto flex flex-col items-center justify-center">
          <i class="fa fa-frown fa-5x text-gray-300"></i>
          <p class="mt-10 text-gray-600 font-semibold text-lg">We could not get any offer</p>
          <p class="text-gray-500 mt-3">Our partners believe your data is insufficient to offer you credit</p>
          <div class="mt-7 flex">
            <AppButton @click="payFull()">Pay full amount {{ $store.state.request.amount | currency }}</AppButton>
          </div>
        </div>
      </template>

      <Modal v-if="modal === 'payment'">
        <VirtualAccountPayment :amount="payment.amount" :upfront="payment.upfront" @done="paymentDone()"/>
      </Modal>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/global/Loader";
import Modal from "@/components/global/Modal";
import AppButton from "@/components/global/Button";
import ajax from "@/mixins/ajax";
import view from "@/mixins/view";
import { formatCurrency, roundToNearestHundred, roundToNearestTen } from "@/lib/utils";
import SectionHeader from "@/components/global/SectionHeader";
import VirtualAccountPayment from "@/components/sections/VirtualAccountPayment";

export default {
  name: "Offers",
  components: { VirtualAccountPayment, SectionHeader, AppButton, Modal, Loader },
  mixins: [ajax, view],
  data() {
    return {
      loading: '',
      offers: [],
      selectedOffer: null,
      swiperOptions: {
        slidesPerView: 1.5,
        spaceBetween: 30,
      },
      accepting: false,
      frequencies: [
        { id: 2, text: 'month(s)', adverb: 'Monthly' },
        { id: 4, text: 'week(s)', adverb: 'Weekly' },
        { id: 1, text: 'day(s)', adverb: 'Daily' },
      ],
      payment: { amount: 0, upfront: false },
      formatCurrency
    }
  },
  async created() {
    if (!this.$store.state.config.show_offers) return this.$emit('done');
    if (!this.offers.length) await this.getOffers();
  },
  methods: {
    async getOffers() {
      this.setLoading('Getting offers..');
      try {
        const { request_id, token, questions } = this.$store.state;
        const { data } = await this.$http.post('/schoolportal/offers', { request_id, token, questions });
        const offer = data?.offers?.length ? data.offers[0] : null;
        if (offer) {
          this.offers = this.generateOffers({
            ...offer,
            upfront: +(offer.upfront.replace(/,/gi, '')),
            amount: +(offer.amount.replace(/,/gi, '')),
            monthly_repayment: +(offer.monthly_repayment.replace(/,/gi, '')),
          });
        } else {
          this.setError('No offers');
        }
      } catch (e) {
        console.dir(e);
      }
      this.setLoading('');
    },
    generateOffers(offer) {
      let plans = [];
      const { duration, upfront, total_repayment } = offer;
      if (!total_repayment) return [];
      const price = total_repayment + upfront;
      plans.push({
        ...offer,
        monthly_repayment: roundToNearestHundred(offer.monthly_repayment),
        upfront: roundToNearestHundred(offer.upfront),
        tenor_type: 2
      });
      if (duration > 2) {
        if (upfront <= (price / 2)) {
          plans.push({
            ...offer,
            monthly_repayment: roundToNearestHundred(price / 2),
            duration: 1,
            upfront: roundToNearestHundred(price / 2),
            tenor_type: 2
          });
        } else {
          plans.push({
            ...offer,
            monthly_repayment: roundToNearestHundred(price - upfront),
            duration: 1,
            upfront: roundToNearestHundred(upfront),
            tenor_type: 2
          });
        }
      }
      if (duration > 3) {
        if (duration % 2 === 0) {
          plans.push({
            ...offer,
            monthly_repayment: roundToNearestHundred((price - upfront) / (duration / 2)),
            duration: duration / 2,
            upfront: roundToNearestHundred(upfront),
            tenor_type: 2
          });
        } else {
          plans.push({
            ...offer,
            monthly_repayment: roundToNearestHundred((price - upfront) / ((duration - 1) / 2)),
            duration: (duration - 1) / 2,
            upfront: roundToNearestHundred(upfront),
            tenor_type: 2
          });
        }
      }
      if (duration > 1) {
        plans.push({
          ...offer,
          monthly_repayment: roundToNearestHundred((price - upfront) / (duration - 1)),
          duration: duration - 1,
          upfront,
          tenor_type: 2
        });
      }
      return plans.map((plan, i) => ({ id: i, ...plan }));
    },
    async acceptOffer() {
      this.accepting = true;
      try {
        const payload = {
          request_id: this.$store.state.request_id,
          token: this.$store.state.token,
          amount: this.selectedOffer.amount,
          duration: this.selectedOffer.duration,
          tenor_type: this.selectedOffer.tenor_type
        };
        const { data } = await this.$http.post('https://mobile.creditclan.com/webapi/v1/loan/modify_offer', payload);
        if (data.status) {
          this.$store.commit('patch', { offer: this.selectedOffer });
          this.payment = { amount: this.selectedOffer.upfront, upfront: true };
          this.modal = 'payment';
        }
      } catch (err) {
        console.log({ err });
        const message = err.response ? err.response.data.message : 'An error occurred, please try again';
        console.log(message);
      }
      this.accepting = false;
    },
    selectOffer(offer) {
      this.selectedOffer = { ...offer };
      this.changeTenorType(offer.tenor_type);
    },
    changeTenorType(v) {
      this.selectedOffer.tenor_type = v;
      const offer = this.offers.find(offer => offer.id === this.selectedOffer.id);
      if (v === 4) {
        this.selectedOffer.duration = offer.duration * 4
        this.selectedOffer.monthly_repayment = roundToNearestHundred(offer.monthly_repayment / 4)
      }
      if (v === 1) {
        this.selectedOffer.duration = offer.duration * 30
        this.selectedOffer.monthly_repayment = roundToNearestTen(offer.monthly_repayment / 30)
      }
      if (v === 2) {
        this.selectedOffer.duration = offer.duration
        this.selectedOffer.monthly_repayment = offer.monthly_repayment
      }
    },
    payFull() {
      this.payment = { amount: this.$store.state.request.amount, upfront: false };
      this.modal = 'payment';
    },
    paymentDone() {
      parent.postMessage({ id: 'cclan-breakpoint', data: { breakpoint: 3, offer: this.selectedOffer } }, '*');
      this.$emit('done');
    }
  },
  computed: {
    tenor_type() {
      return this.frequencies.find(f => this.selectedOffer.tenor_type === f.id) || {}
    }
  }
}
</script>
