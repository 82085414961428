<template>
  <div class="flex flex-col h-full overflow-hidden">
    <div class="flex flex-col h-full overflow-y-auto pb-32 pt-8">
      <div class="my-auto">
        <SectionHeader
            title="Almost done"
            subtitle="Kindly click the button below to send us a confirmation message on whatsapp"
        />
        <button v-if="!sent" @click="sendChat()" class="px-3 py-2 bg-green-500 text-white rounded-lg mt-4">
          <i class="fab fa-whatsapp mr-2"></i>
          Send whatsapp message
        </button>
        <template v-else>
          <AppButton @click="$emit('done')">Continue</AppButton>
        </template>
      </div>
    </div>
  </div>
</template>
<script>

import SectionHeader from "@/components/global/SectionHeader";
import AppButton from "@/components/global/Button";

export default {
  name: 'WhatsappNotify',
  components: { AppButton, SectionHeader },
  data() {
    return {
      sent: false
    }
  },
  methods: {
    sendChat() {
      const url = `https://wa.me/+2349155577731?text=Hello Creditclan, I have completed my application`;
      window.open(url, '_blank');
      this.$emit('done');
    },
  }
}
</script>
