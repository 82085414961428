<template>
  <div class="flex flex-col h-full overflow-hidden">
    <QuestionsStatus/>
    <div class="flex flex-col h-full overflow-y-auto pb-32 pt-8">
      <div v-if="!change" class="my-auto">
        <SectionHeader
          title="Is this your Home address?"
          :subtitle="`${home_address.address}, ${getStateName()}`"
        />
        <div class="flex flex-col gap-3">
          <div @click="next()" class="border px-5 py-3 cursor-pointer rounded-lg flex items-center justify-between">
            Yes <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
          </div>
          <div @click="change = true" class="border px-5 py-3 cursor-pointer rounded-lg flex items-center justify-between">
            No <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
          </div>
        </div>
      </div>
      <div v-else class="my-auto">
        <SectionHeader title="Enter your home address"/>
        <div class="mt-10">
          <ValidationObserver ref="observer">
            <form class="flex flex-col space-y-3">
              <ValidationProvider
                name="address"
                rules="required|address"
                v-slot="{ errors }"
              >
                <AppTextArea
                  placeholder="Address"
                  v-model="values.address"
                  :errors="errors"
                />
              </ValidationProvider>
              <ValidationProvider name="state" rules="required" v-slot="{ errors }">
                <AppSelect v-model="values.state" :errors="errors">
                  <option value="">State</option>
                  <option v-if="!states.length" value="" disabled>Loading</option>
                  <option
                    v-for="state in states"
                    :key="state.value"
                    :value="state.value"
                  >
                    {{ state.text }}
                  </option>
                </AppSelect>
              </ValidationProvider>
            </form>
            <div class="mt-10 flex items-center">
              <button
                @click="change = false"
                class="border border-gray-500 text-gray-700 px-3 py-1 mr-2 rounded-lg text-sm"
              >
                <i class="fa fa-chevron-left mr-2"></i> Back
              </button>
              <AppButton @click="save()">Continue</AppButton>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	import SectionHeader from "@/components/global/SectionHeader";
	import AppTextArea from "@/components/global/TextArea";
	import AppSelect from "@/components/global/Select";
	import AppButton from "@/components/global/Button";
	import states from "@/lib/states";
	import QuestionsStatus from "@/components/sections/QuestionsStatus";

	export default {
		name: "ConfirmAddress",
		components: { AppButton, AppSelect, AppTextArea, SectionHeader, QuestionsStatus },
		data() {
			return {
				home_address: { ...this.$store.state.home_address },
				change: false,
				values: {
					address: "",
					state: "",
				},
				states,
			};
		},
		created() {
			const { recently, twoWeeks, oneMonth } = this.$store.getters["lastApplied"];
			if (recently || twoWeeks || oneMonth) return this.$emit("done");
			if (!this.home_address.address) this.change = true;
		},
		methods: {
			next() {
				this.$emit("done");
			},
			async save() {
				if (!(await this.$refs.observer.validate())) return;
				this.$store.commit("home_address", this.values);
				this.next();
			},
			getStateName() {
				return states.find(
					(s) => s.value.toString() === this.home_address.state_id.toString()
				)?.text;
			},
		},
	};
</script>
