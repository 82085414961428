<template>
  <div class="h-full flex flex-col">
    <div v-if="overlay" class="h-full flex flex-col">
      <div v-if="overlay === 'info'" class="flex flex-col items-center justify-center my-auto text-center">
        <img src="@/assets/images/question.svg" alt="questions" class="w-48"/>
        <h2 class="text-2xl mt-16 max-w-sm">Please answer as many questions as possible within the next 60 seconds.</h2>
        <Message class="mt-6 text-sm" variant="info">The more questions you answer the better your chances</Message>
        <AppButton @click="init()" class="mt-6">Continue</AppButton>
      </div>
      <RandomQuestions v-if="overlay === 'random-questions'" @done="overlay = ''"/>
    </div>
    <div v-else class="h-full flex flex-col">
      <template v-if="loading">
        <div class="my-auto">
          <Loader
              v-if="loading === 'reconfirm'"
              text="We know you are legit"
              subtext="Let's reconfirm data with us"
          />
          <Loader
              v-else-if="loading === 'sneakers'"
              text="Lacing our sneakers"
              :subtext="`We will find data that supports you`"
          />
          <Loader
              v-else-if="loading === 'confirming-numbers'"
              text="Confirming your number"
              subtext="We just crossed ojuelega Bridge looking for more data to support you"
          />
          <Loader v-else-if="loading === '3-seconds'" text="3 seconds more"/>
          <Loader v-else :text="loading"/>
        </div>
      </template>
      <template v-else>
        <div class="flex justify-between">
          <button
              @click="popView()" v-if="history.length > 1 && !loading"
              class="w-8 h-8 rounded-full text-gray-500 bg-gray-50 border text-sm flex items-center justify-center"
          >
            <i class="fa fa-chevron-left"></i>
          </button>
          <span v-else></span>
          <button @click="$emit('close')" class="py-1 px-3 border border-red-700 rounded-lg text-red-700 text-sm">
            <i class="fa fa-times"></i>
          </button>
        </div>
        <div class="h-full flex flex-col">
          <div class="my-auto">
            <Error v-if="error" :text="error" show-retry @retry="checkData()"/>
            <template v-else>
              <ConfirmAddress v-if="view === 'confirm-address'" @done="pushView('work')"/>
              <WorkInformation v-if="view === 'work'" @done="pushView('social-handles')"/>
              <SocialHandles v-if="view === 'social-handles'" @done="pushView('fcn')"/>
              <FrequentlyCalledNumbers v-if="view === 'fcn'" @done="questionsDone()"/>
              <NoRecordsFound v-if="view === 'no-record'" @view="pushView($event)" @done="checksDone()"/>
              <NationalIdentityNumber v-if="view === 'nin'" @view="pushView($event)" @done="checksDone()"/>
              <Income v-if="view === 'income'" @done="pushView('account')"/>
              <BankAccount v-if="view === 'account'" @done="pushView('bank-statement')" @cancel="$emit('done')"/>
              <BankStatement v-if="view === 'bank-statement'" @done="$emit('done')" @back="popView()"/>
            </template>
          </div>
        </div>
      </template>

      <img v-if="loading === 'sneakers'" src="@/assets/images/sneaker.svg" alt="shield" class="w-28 absolute bottom-5 right-5 opacity-50"/>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/global/Loader";
import NoRecordsFound from "@/components/sections/NoRecordsFound";
import NationalIdentityNumber from "@/components/sections/NationalIdentityNumber";
import BankAccount from "@/components/sections/BankAccount";
import { delay } from "@/lib/utils";
import Error from "@/components/global/Error";
import ajax from "@/mixins/ajax";
import view from "@/mixins/view";
import BankStatement from "@/components/sections/BankStatement";
import ConfirmAddress from "@/components/sections/ConfirmAddress";
import SocialHandles from "@/components/sections/SocialHandles";
import FrequentlyCalledNumbers from "@/components/sections/FrequentlyCalledNumbers";
import Income from "@/components/sections/Income";
import RandomQuestions from "@/components/sections/RandomQuestions";
import Message from "../global/Message";
import AppButton from "../global/Button";
import WorkInformation from "@/components/sections/WorkInformation";

export default {
  name: 'RunChecks',
  components: {
    WorkInformation,
    AppButton,
    Message,
    RandomQuestions,
    Income,
    FrequentlyCalledNumbers,
    SocialHandles,
    ConfirmAddress,
    BankStatement,
    Error,
    BankAccount,
    NationalIdentityNumber,
    NoRecordsFound,
    Loader
  },
  mixins: [ajax, view],
  created() {
    if (this.$store.state.config.show_questions) this.overlay = 'info';
    else this.init();
  },
  methods: {
    async init() {
      this.overlay = '';
      this.setLoading('sneakers');
      await delay(3000);
      this.setLoading('');
      this.pushView('confirm-address');
    },
    questionsDone() {
      this.overlay = '';
      if (!this.$store.state.has_data) return this.checkData();
      this.checksDone();
    },
    checksDone() {
      if (this.$store.state.has_data) this.runFaceMatch();
      this.pushView('income');
    },
    async runFaceMatch() {
      await this.$http.post('/customer/facematch', {
        token: this.$store.state.token
      });
    },
    async checkData() {
      this.overlay = 'random-questions';
      try {
        if (this.$store.state.new_customer) this.setLoading('confirming-numbers');
        else this.setLoading('reconfirm');
        const { data } = await this.$http.post('/customer/checkdata', { token: this.$store.state.token });
        await delay(2000);
        this.$store.commit('patch', { has_data: data.data.has_data });
        if (data.data.has_data) this.pushView('income');
        else this.pushView('no-record');
      } catch (e) {
        this.setError('An error occurred, please try again!');
      }
      this.setLoading('');
    },
  }
}
</script>
