<template>
  <div class="h-full flex flex-col">
    <PreAnalysis v-if="view === 'pre-analysis'" @done="preAnalysisDone()"/>
    <PostAnalysis v-if="view === 'post-analysis'" @done="postAnalysisDone()"/>
    <WhatsappNotify v-else-if="view === 'whatsapp'" @done="pushView('success')"/>
    <Success v-if="view === 'success'"/>
  </div>
</template>

<script>
import PreAnalysis from "@/views/PreAnalysis";
import view from '@/mixins/view';
import PostAnalysis from "@/views/PostAnalysis";
import Success from "@/components/sections/Success";
import WhatsappNotify from "@/components/sections/WhatsappNotify";

export default {
  name: "Analysis",
  components: { WhatsappNotify, Success, PostAnalysis, PreAnalysis },
  mixins: [view],
  created() {
    const { stage, request_id } = this.$store.state;
    if (request_id && stage > 1) return this.pushView('post-analysis');
    this.pushView('pre-analysis');
  },
  methods: {
    preAnalysisDone() {
      this.pushView('post-analysis');
    },
    postAnalysisDone() {
      const { notify_whatsapp } = this.$store.state.config;
      if (notify_whatsapp) return this.pushView('whatsapp');
      this.pushView('success');
    },
  }
}
</script>
