<template>
  <div class="py-14 my-auto">
    <template v-if="response">
      <SectionHeader
        title="Thank you for providing your bank statement"
        subtitle="Click the button below to continue"
      />
      <div class="flex mt-10">
        <AppButton @click="$emit('done')">Continue</AppButton>
      </div>
    </template>
    <template v-else>
      <template v-if="view === 'online-banking'">
        <SectionHeader title="Do you use internet banking?"/>
        <div class="flex flex-col gap-4">
          <button @click="setHasOnlineBanking(1)" class="text-left py-3 px-5 border rounded-lg flex items-center justify-between">
            Yes, I use internet banking
            <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
          </button>
          <button @click="setHasOnlineBanking(0)" class="text-left py-3 px-5 border rounded-lg flex items-center justify-between">
            No, I don't
            <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
          </button>
        </div>
      </template>
      <template v-if="view === 'local'">
        <BSLocal @back="cancel()" @done="localDone($event)"/>
      </template>
      <template v-if="view === 'mono'">
        <BSMono @back="cancel()" @done="monoDone($event)"/>
      </template>
      <template v-if="view === 'mbs'">
        <BSMbs @back="cancel()" @done="mbsDone($event)"/>
      </template>
      <template v-if="view === 'home'">
        <SectionHeader
          title="Analyse bank statement"
          subtitle="Creditclan has requested for your bank statement to process this request, click the button below to continue"
        />
        <div class="flex mt-10">
          <AppButton @click="start()">Start</AppButton>
        </div>
        <button v-if="fallback" @click="view = 'local'" class="text-gray-600 mt-3 text-sm">Upload manually</button>
      </template>
    </template>
  </div>
</template>

<script>
	import SectionHeader from "@/components/global/SectionHeader";
	import AppButton from "@/components/global/Button";
	import BSLocal from "@/components/sections/BSLocal";
	import BSMono from "@/components/sections/BSMono";
	import BSMbs from "@/components/sections/BSMbs";
	import banks from "@/lib/mbs-banks";

	export default {
		name: "BankStatement",
		components: { BSMbs, BSMono, BSLocal, AppButton, SectionHeader },
		data() {
			return {
				view: 'online-banking',
				has_online_banking: 0,
				fallback: false
			}
		},
		created() {
			if (!this.$store.state.config.analyze_bank_statement) return this.$emit('done');
			if (!this.$store.state.account.bank_code) return this.$emit('done');
			const { recently, twoWeeks } = this.$store.getters['lastApplied'];
			if (recently || twoWeeks) return this.$emit('done');
			if (this.$store.state.okra_id) return this.$emit('done');
		},
		methods: {
			setHasOnlineBanking(value) {
				this.has_online_banking = value;
				this.start();
			},
			start() {
				if (this.has_online_banking) this.view = 'mono';
				else {
					const bank = banks.find(b => b.sortCode === this.$store.state.account.bank_code);
					if (bank) this.view = 'mbs';
					else this.view = 'local';
				}
			},
			localDone(transaction_id) {
				this.$store.commit('bs', {
					response: {
						statement_source: 1,
						filename: { transaction_id },
						has_online_banking: this.has_online_banking
					}
				});
			},
			monoDone(response) {
				this.$store.commit('bs', {
					response: {
						statement_source: 4,
						filename: response,
						has_online_banking: this.has_online_banking
					}
				});
			},
			mbsDone(transaction_id) {
				this.$store.commit('bs', {
					response: {
						statement_source: 3,
						filename: { transaction_id },
						has_online_banking: this.has_online_banking
					}
				});
			},
			cancel() {
				this.view = 'local';
				this.fallback = true;
			}
		},
		computed: {
			response() {
				return this.$store.state.bs.response;
			}
		}
	}
</script>
