<template>
  <div>
    <Loader v-if="loading" :text="loading" class="my-auto"/>
    <Error v-else-if="error" :text="error" :show-retry="!!retryCb" @retry="retry()" :show-back="!!backCb" @back="back()"/>
    <div v-else class="my-auto">
      <template v-if="view === 'new'">
        <SectionHeader
            title="Add repayment card"
            :subtitle="`Provide your card details for direct debit, a small fee of N${amount} will be deducted from your card`"
        />
        <div
            v-if="cards.length" @click="view = 'cards'"
            class="bg-gray-100 text-gray-700 px-4 py-3 rounded-lg inline-flex items-center justify-center cursor-pointer border border-dashed mb-4"
        >
          <i class="fa fa-credit-card mr-3"></i>View cards
        </div>
        <br>
        <template v-if="!card">
          <Paystack v-if="method === 'paystack'" :amount="amount" :email="$store.state.profile.email" @done="paymentDone($event)">
            <div class="bg-blue-900 text-white px-36 py-16 rounded-lg flex flex-col items-center text-center justify-center">
              <i class="fa fa-plus fa-2x mb-2 text-gray-300"></i>Add card
            </div>
          </Paystack>

          <Flutterwave
              v-if="method === 'flutterwave'" :amount="amount" @done="paymentDone($event)"
              :customer="{ name: $store.state.profile.full_name, email: $store.state.profile.email, phone_number: $store.state.profile.phone }"
          >
            <div class="bg-blue-900 text-white px-36 py-16 rounded-lg flex flex-col items-center text-center justify-center">
              <i class="fa fa-plus fa-2x mb-2 text-gray-300"></i>
              Add card
            </div>
          </Flutterwave>
        </template>

        <div v-else>
          <AppButton class="mt-10">Continue</AppButton>
        </div>
      </template>
      <template v-if="view === 'cards'">
        <SectionHeader title="Select repayment card" :subtitle="`Select a card below to setup direct debit for this request`"/>
        <div
            @click="view = 'new'"
            class="bg-gray-100 text-gray-700 px-4 py-3 rounded-lg inline-flex items-center justify-center cursor-pointer border border-dashed mb-4"
        >
          <i class="fa fa-plus mr-3"></i>Add new card
        </div>
        <swiper ref="mySwiper" :options="{ slidesPerView: 1.5, spaceBetween: 20 }">
          <template v-for="card in cards">
            <swiper-slide :key="card.card_id">
              <div @click="initChargeCard(card)" class="bg-blue-900 text-white px-8 py-7 rounded-lg flex flex-col items-stretch cursor-pointer">
                <p class="text-yellow-500">{{ card.bank }}</p>
                <div class="text-3xl font-semibold my-auto py-8">**** **** **** {{ card.last4 }}</div>
                <p>{{ card.expiry_date }}</p>
              </div>
            </swiper-slide>
          </template>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </template>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader";
import AppButton from "@/components/global/Button";
import Paystack from "@/components/global/Paystack";
import Flutterwave from "@/components/global/Flutterwave";
import Loader from "@/components/global/Loader";
import ajax from "@/mixins/ajax";
import ErrorComponent from "@/components/global/Error";

export default {
  name: "RepaymentCard",
  components: { Error: ErrorComponent, Loader, Flutterwave, AppButton, SectionHeader, Paystack },
  mixins: [ajax],
  data() {
    return {
      card: null,
      cards: [],
      view: '',
      method: 'flutterwave',
      token: null,
      amount: 5,
      paystack: { amount: 1, key: null }
    }
  },
  created() {
    if (!this.$store.state.config.tokenize_card) return this.$emit('done')
    this.getCards();
  },
  methods: {
    init() {
      this.setLoading('Initializing..');
      this.$http.post('https://mobile.creditclan.com/api/v3/card/tokenization', {
        request_id: this.$store.state.request_id
      }).then(({ data }) => {
        const { paystack, flutterwave } = data.data;
        if (paystack.must_added) this.method = 'paystack';
        else if (flutterwave.must_added) this.method = 'flutterwave';
        this.token = data.token;
        this.setLoading('');
      }).catch(() => {
        this.setError('An error occurred, please try again', true);
        this.setRetry(() => this.check());
      });
    },
    check() {
      this.setLoading('Checking for card..');
      this.$http.post('https://mobile.creditclan.com/api/v3/loan/checklists', {
        request_id: this.$store.state.request_id, token: this.$store.state.token
      }).then(res => {
        if (!res.data.data.card) this.init();
        this.$emit('done');
      }).catch(() => {
        this.setError('An error occurred, please try again', true);
        this.setRetry(() => this.check());
      });
    },
    async getCards() {
      this.setLoading('Getting cards..');
      try {
        const res = await this.$http.post('/user/cards', { token: this.$store.state.token });
        this.cards = res.data.data
        this.view = this.cards.length ? 'cards' : 'new';
        this.init();
      } catch (e) {
        this.setError(e.response ? e.response.data.message : e.message || 'An error occurred, please try again');
        this.setRetry(() => this.getCards())
      }
    },
    initChargeCard(card) {
      try {
        this.setLoading('Charging card..');
        const { EXP_YEAR, EXP_MONTH } = card || {};
        const now = new Date();
        if (+EXP_YEAR > now.getFullYear()) return this.chargeCard(card);
        if (+EXP_YEAR === now.getFullYear() && (+EXP_MONTH - now.getMonth() + 1) >= 6) return this.chargeCard(card);
        this.setError('Sorry this card cannot be used for this request, please use a different card');
        this.setBack(() => this.reset());
      } catch (e) {
        this.setError(e.message);
      }
    },
    async chargeCard(card) {
      try {
        const res = await this.$http.post('/customer/paystack/charge/card', {
          token: this.$store.state.token,
          request_id: this.$store.state.request_id,
          account_card_id: card.ACCOUNT_CARD_ID
        });
        if (res.data.status) return this.$emit('done');
        this.setError(res.data.message || 'An error occurred, please try again', true);
        this.setBack(() => this.reset());
        this.setRetry(() => this.initChargeCard(card));
      } catch (e) {
        this.setError(e.response ? e.response.data.message : e.message || 'An error occurred, please try again');
        this.setBack(() => this.reset());
        this.setRetry(() => this.initChargeCard(card));
      }
    },
    async paymentDone(response) {
      this.setLoading('Submitting..');
      try {
        if (this.method === 'paystack') await this.submitPaystack(response.reference);
        else if (this.method === 'flutterwave') await this.submitFlutterwave(response);
      } catch (e) {
        this.setError(e.response ? e.response.data.message : e.message || 'An error occurred, please try again');
        this.setRetry(() => this.paymentDone(response));
      }
      this.setLoading('');
    },
    async submitPaystack(reference) {
      const res = await this.$http.post('/tokennization/add/newcard', { token: this.token, reference });
      if (res.data.status) return this.next();
      throw new Error(res.data.message);
    },
    async submitFlutterwave(data) {
      const res = await this.$http.post('/tokennization/add/newcard', { token: this.token, data });
      if (res.data.status) return this.next();
      throw new Error(res.data.message);
    },
    next() {
      this.$emit('done');
    }
  }
}
</script>
