import axios from 'axios';

const API_KEY = process.env.VUE_APP_API_KEY;

const http = axios.create({
	baseURL: 'https://mobile.creditclan.com/api/v3'
});

http.interceptors.request.use(config => {
	config.headers['x-api-key'] = API_KEY;
	return config;
}, error => {
	return Promise.reject(error);
});

export default http;
