<template>
  <div class="flex flex-col h-full overflow-hidden">
    <QuestionsStatus/>
    <div class="flex flex-col h-full overflow-y-auto pb-32 pt-8">
      <div class="my-auto">
        <template v-if="questions[current]">
          <SectionHeader
              :title="questions[current].question"
              :subtitle="questions[current].rider"
          />
          <div class="flex flex-col gap-3">
            <div
                @click="chooseAnswer(answer)"
                v-for="(answer, i) in questions[current].answers"
                :key="i"
                class="border px-5 py-3 cursor-pointer rounded-lg flex items-center justify-between"
            >
              {{ answer }}
              <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader";
import ajax from "@/mixins/ajax";
import QuestionsStatus from "./QuestionsStatus";

export default {
  name: "RandomQuestions",
  components: { QuestionsStatus, SectionHeader },
  mixins: [ajax],
  data() {
    return {
      questions: this.$store.state.questions.filter(
          (q) => !q.user_answer
      ),
      current: 0,
    };
  },
  created() {
    if (!this.$store.state.config.show_questions || !this.questions.length) return this.$emit("done");
    if (!this.$store.state.timer_start) return this.$emit('done');
  },
  methods: {
    chooseAnswer(answer) {
      this.questions[this.current].user_answer = answer[0];
      const questions = this.$store.state.questions.map((q) => {
        const exist = this.questions.find((q2) => q2.id === q.id);
        return exist || q;
      });
      this.$store.commit("patch", { questions });
      if (!this.$parent.loading) return this.$emit("done");
      if (this.current < this.questions.length - 1) this.current++;
      else this.$emit("done");
    },
  },
};
</script>
