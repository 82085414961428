import Vue from 'vue';
import Vuex from 'vuex';
import http from "@/plugins/http";
import { addMinutes, differenceInDays } from "date-fns";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        request: {
            amount: 0,
            duration: 0,
            tenor_type: 2
        },
        profile: {
            user_id: null,
            full_name: '',
            email: '',
            phone: '',
            profile_image: ''
        },
        account: {
            bvn: null,
            account_name: '',
            account_number: '',
            bank_id: '',
            bank_code: '',
        },
        home_address: {
            address: '',
            state_id: '',
        },
        work: {
            monthly_income: '',
            work_sector: '',
            occupation_id: '',
            address: '',
            start_month: '',
            start_year: '',
            company_name: ''
        },
        identity: {
            nin: ''
        },
        frequently_called_numbers: [{ phone: '' }, { phone: '' }],
        social: {
            twitter: '',
            instagram: '',
        },
        has_data: false,
        questions: [],
        request_id: null,
        new_customer: false,
        okra_id: null,
        token: null,
        offer: null,
        stage: 0,
        bs: {
            has_online_banking: 0,
            response: null
        },
        config: {
            no_frequently_called_numbers: 2,
            analyze_bank_statement: true,
            tokenize_card: true,
            show_offers: true,
            show_questions: true,
            show_bank_account: true,
            match_account_with_bvn: false,
            show_work_information: false,
            notify_whatsapp: false
        },
        extra: {},
        last_application_date: null,
        timer_end: addMinutes(new Date(Date.now()), 2),
        timer_start: new Date(Date.now())
    },
    getters: {
        lastApplied: state => {
            if (!state.last_application_date) return {
                recently: false,
                twoWeeks: false,
                oneMonth: false,
                threeMonths: false
            };
            const date = new Date(state.last_application_date);
            const today = new Date(Date.now());
            const difference = differenceInDays(today, date);
            return {
                recently: difference <= 2,
                twoWeeks: difference > 2 && difference <= 14,
                oneMonth: difference > 14 && difference <= 30,
                threeMonths: difference > 30 && difference <= 60
            };
        }
    },
    mutations: {
        patch: (state, data) => {
            Object.keys(data).forEach(key => {
                state[key] = data[key];
            });
        },
        profile: (state, data) => {
            Object.keys(data).forEach(key => {
                state.profile[key] = data[key];
            });
        },
        home_address: (state, data) => {
            Object.keys(data).forEach(key => {
                state.home_address[key] = data[key];
            });
        },
        work: (state, data) => {
            Object.keys(data).forEach(key => {
                state.work[key] = data[key];
            });
        },
        account: (state, data) => {
            Object.keys(data).forEach(key => {
                state.account[key] = data[key];
            });
        },
        bs: (state, data) => {
            Object.keys(data).forEach(key => {
                state.bs[key] = data[key];
            });
        },
        social: (state, data) => {
            Object.keys(data).forEach(key => {
                state.social[key] = data[key];
            });
        },
        config: (state, data) => {
            Object.keys(data).forEach(key => {
                state.config[key] = data[key];
            });
        },
    },
    actions: {
        async postData({ state }, data) {
            const payload = { token: state.token, amount: state.request.amount, };
            if (data.type === 'phone') payload.phone = state.profile.phone;
            if (data.type === 'nin') payload.nin = state.identity.nin;
            if (data.type === 'bvn') payload.bvn = state.account.bvn;
            const res = await http.post('/customer/getdata', { ...payload, ...data });
            return res.data;
        }
    },
    modules: {}
});
