<template>
  <div class="h-full flex flex-col">
    <div class="text-center mx-auto flex flex-col items-center my-auto">
      <div class="w-24 h-24 rounded-full bg-green-600 flex items-center justify-center">
        <i class="fa fa-check text-4xl text-white"></i>
      </div>
      <div class="px-5 mt-10 text-center">
        <p class="font-semibold text-lg">Application successful</p>
        <p class="text-gray-500 mt-3">You request has been sent to the lender</p>
        <div class="flex flex-col items-center mt-10">
          <button @click="close()" class="px-3 py-1 border border-red-500 text-red-500 rounded-lg">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Success',
  methods: {
    close() {
      const { request_id, profile: { user_id }, offer } = this.$store.state;
      parent.postMessage({ id: 'cclan-breakpoint', data: { breakpoint: 4 } }, '*');
      parent.postMessage({ id: 'cclan-request', data: { request_id, user_id, offer } }, '*');
    }
  }
}
</script>
