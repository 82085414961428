<template>
  <div class="text-center max-w-xs mx-auto flex flex-col items-center justify-center">
    <i class="fa fa-exclamation-circle fa-4x text-red-400"></i>
    <p v-if="text" class="mt-10 text-gray-600 font-semibold text-lg">{{ text }}</p>
    <p v-if="subtext" class="text-gray-500 mt-3">{{ subtext }}</p>
    <div class="mt-7 flex gap-4">
      <button v-if="showBack" @click="$emit('back', $event)" class="text-gray-400 rounded-lg py-1 px-3">
        <i class="fa fa-arrow-left mr-2"></i> Back
      </button>
      <button v-if="showRetry" @click="$emit('retry', $event)" class="text-red-400 border border-red-400 rounded-lg py-1 px-3">
        <i class="fa fa-redo-alt mr-2"></i> Retry
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error",
  props: {
    text: {
      type: String,
    },
    subtext: {
      type: String
    },
    showBack: {
      type: Boolean,
      default: false
    },
    showRetry: {
      type: Boolean,
      default: false
    }
  }
}
</script>
