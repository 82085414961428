<template>
  <div v-if="overlay" class="my-auto w-full">
    <RandomQuestions @done="overlay = ''"/>
  </div>
  <div v-else>
    <Loader v-if="loading" :text="loading"/>
    <template v-else>
      <Error v-if="error" :text="error" show-retry @retry="init()"/>
      <template v-else>
        <div v-if="currentView === 'institutions'" class="card">
          <div class="flex justify-between align-center mb-10">
            <div class="flex">
              <h3 class="text-gray-800 font-semibold ml-3">Institutions</h3>
            </div>
            <div class="flex gap-4">
              <div class="text-gray-600 text-sm cursor-pointer" :class="{'text-blue-600': type === 'PERSONAL_BANKING'}"
                   @click="type = 'PERSONAL_BANKING'">
                Personal
              </div>
              <div class="text-gray-600 text-sm cursor-pointer" :class="{'text-blue-600': type === 'BUSINESS_BANKING'}"
                   @click="type = 'BUSINESS_BANKING'">
                Business
              </div>
            </div>
          </div>
          <div
              @click="selectInstitution(item)" class="border bg-gray-50 rounded-lg flex items-center mb-3 cursor-pointer p-3"
              v-for="item in [...institutions].filter(i => i.type === this.type)" :key="item._id"
          >
            <img :src="item.icon" style="width:50px" class="rounded" alt="icon"/>
            <div class="ml-3 flex flex-col">
              <h5 class="text-gray-800">{{ item.name }}</h5>
              <small class="text-xs text-gray-600">{{ item.auth_methods.map(v => v.name).join(' • ') }}</small>
            </div>
          </div>
        </div>
        <div v-if="currentView === 'auth_method'" class="card">
          <div class="flex mb-10">
            <h3 class="text-gray-800 font-semibold ml-3">Choose login method</h3>
          </div>
          <div
              class="border bg-gray-50 rounded-lg flex justify-between items-center mb-3 cursor-pointer py-3 px-5" @click="selectAuthMethod(item)"
              v-for="item in selected.auth_methods" :key="item.type"
          >
            <h5 class="text-gray-800">{{ item.name }}</h5>
            <i class="fa fa-long-arrow-alt-right"></i>
          </div>
        </div>
        <div v-if="currentView === 'form'" class="card">
          <div class="flex justify-between items-center mb-6">
            <div class="flex">
              <h3 class="text-gray-800 font-semibold">{{ selected.ui.title }}</h3>
            </div>
            <img :src="selected.icon" class="w-16 rounded" alt="">
          </div>
          <Message v-if="loginError" variant="error" class="mb-6">{{ loginError }}</Message>
          <Message v-else class="text-sm mb-6">
            Disclaimer: We do not have access to transfer from your account, the purpose is just for us to evaluate you bank transaction
          </Message>
          <form>
            <div class="flex flex-col gap-4">
              <AppInput v-model="values.username" :placeholder="selected.ui.form[0].hint"/>
              <AppInput type="password" v-model="values.password" :placeholder="selected.ui.form[1].hint "/>
            </div>
            <div class="mt-10">
              <AppButton @click="submit()">Login</AppButton>
            </div>
            <button @click="$emit('back')" class="text-gray-600 mt-3 text-sm">Upload bank statement file instead</button>
            <p class="mt-10 relative text-sm text-yellow-400"><i class="fa fa-bolt mr-2"></i>
              Powered by Mono
            </p>
          </form>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import Loader from "@/components/global/Loader";
import AppInput from "@/components/global/Input";
import AppButton from "@/components/global/Button";
import ajax from "@/mixins/ajax";
import Message from "@/components/global/Message";
import Error from "@/components/global/Error";
import view from '@/mixins/view';
import RandomQuestions from "@/components/sections/RandomQuestions";

export default {
  name: "BSMono",
  components: { RandomQuestions, Error, Message, AppButton, AppInput, Loader },
  mixins: [ajax, view],
  data() {
    return {
      settings: null,
      institutions: [],
      currentView: '',
      type: 'PERSONAL_BANKING',
      auth_method: '',
      selected: null,
      session: null,
      values: {
        username: '',
        password: ''
      },
      auto: false,
      loginError: ''
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      if (!this.settings) await this.authenticate();
      await this.autoSelectInstitution();
    },
    async autoSelectInstitution() {
      this.setLoading('Please wait..');
      try {
        const res = await this.$http.get('https://mobile.creditclan.com/webapi/v1/banks_mono');
        this.banks = res.data.data;
        const { bank_id } = this.$store.state.account;
        const institution = this.banks.find(bank => `${ bank.id }` === `${ bank_id }`)?.mono_bank_data;
        if (institution) {
          this.selectInstitution(institution);
          this.auto = true;
          this.selectAuthMethod(institution.auth_methods[0]);
        }
      } catch (e) {
        this.setError('An error occurred, please try again');
      }
      this.setLoading('');
    },
    async authenticate() {
      try {
        this.setLoading('Please wait..');
        const { data } = await this.$http.post('https://api.withmono.com/partners/login', {
          email: "technical@creditclan.com",
          password: "cr3d!tcl@nDonotD3l3t3!@t@!!@gain!s@y@t@ll"
        });
        this.settings = data;
      } catch (err) {
        this.$emit('back');
      }
      this.setLoading('');
    },
    selectInstitution(institution) {
      this.selected = institution;
      const mobile_auth_method = this.selected.auth_methods.find(m => m.type === 'mobile_banking');
      const internet_auth_method = this.selected.auth_methods.find(m => m.type === 'internet_banking');
      if (mobile_auth_method) this.selectAuthMethod(mobile_auth_method);
      else if (internet_auth_method) this.selectAuthMethod(internet_auth_method);
    },
    selectAuthMethod(type) {
      this.auth_method = type;
      this.currentView = 'form';
    },
    unselect() {
      if (this.auto) return this.$emit('back');
      this.currentView = 'institutions';
      this.selected = null;
    },
    async submit() {
      this.overlay = 'random-questions';
      this.setLoading('Confirming..');
      try {
        if (!this.session) await this.createSession();
        const { data } = await this.$http.post('https://api.withmono.com/v1/connect/login', { ...this.values }, {
          headers: { "Authorization": `Bearer ${ this.settings.token }`, "x-session-id": this.session.id }
        });
        this.$emit('done', data.data);
        if (data.responseCode !== 99) await this.commitSession();
      } catch (err) {
        if (this.selected.auth_methods.length > 1 && this.auth_method.type === 'mobile_banking') {
          const internet_auth_method = this.selected.auth_methods.find(m => m.type === 'internet_banking');
          if (internet_auth_method) {
            this.selectAuthMethod(internet_auth_method);
            await this.submit();
          }
        } else {
          const mobile_auth_method = this.selected.auth_methods.find(m => m.type === 'mobile_banking');
          if (mobile_auth_method) this.selectAuthMethod(mobile_auth_method);
        }
        this.loginError = err.response ? err.response.data.message : 'An error occurred, please try again';
      }
      this.setLoading('');
    },
    async createSession() {
      const res = await this.$http.post('https://api.withmono.com/v1/connect/session', {
        "app": "6013d2c815222620a13a6956",
        "institution": this.selected._id,
        "auth_method": this.auth_method.type,
      }, { headers: { "Authorization": `Bearer ${ this.settings.token }` } });
      this.session = res.data;
    },
    async commitSession() {
      await this.$http.post('https://api.withmono.com/v1/connect/session', undefined, {
        headers: { "Authorization": `Bearer ${ this.settings.token }`, "x-session-id": this.session.id }
      });
    }
  }
}
</script>
