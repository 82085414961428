<template>
  <div class="text-center mx-auto flex flex-col items-center py-14 my-auto">
    <div class="w-24 h-24 rounded-full bg-green-600 flex items-center justify-center">
      <i class="fa fa-check text-4xl text-white"></i>
    </div>
    <div class="px-5 mt-10 text-center max-w-xs">
      <p class="font-semibold text-lg">Your offer is ready</p>
      <p class="text-gray-500 mt-3">Our finance partners however need a few clarifications</p>
    </div>
    <div class="flex mt-10">
      <AppButton @click="$emit('done')">Continue</AppButton>
    </div>
  </div>
</template>
<script>
import AppButton from "@/components/global/Button";

export default {
  name: 'OfferReady',
  components: { AppButton }
}
</script>
