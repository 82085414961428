var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col h-full overflow-hidden"},[_c('div',{staticClass:"flex flex-col h-full overflow-y-auto pb-32 pt-8"},[_c('div',{staticClass:"my-auto"},[_c('SectionHeader',{attrs:{"title":"Provide work information","subtitle":"Please fill in all fields correctly"}}),_c('form',[_c('ValidationObserver',{ref:"observer"},[_c('div',{staticClass:"flex flex-col space-y-4"},[_c('ValidationProvider',{attrs:{"name":"occupation_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"flex mb-2 text-gray-600"},[_vm._v("Employment status")]),_c('AppSelect',{staticClass:"w-full",attrs:{"errors":errors,"disabled":_vm.loading},model:{value:(_vm.values.occupation_id),callback:function ($$v) {_vm.$set(_vm.values, "occupation_id", $$v)},expression:"values.occupation_id"}},[_c('option',{attrs:{"disabled":"","selected":""}},[_vm._v("Select employment status")]),_vm._l((_vm.occupations),function(status){return _c('option',{key:status.value,domProps:{"value":status.value}},[_vm._v(" "+_vm._s(status.text)+" ")])})],2)]}}])}),_c('ValidationProvider',{attrs:{"name":"Place of work","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"flex mb-2 text-gray-600"},[_vm._v("Place of work")]),_c('AppInput',{staticClass:"w-full",attrs:{"type":"text","errors":errors,"disabled":_vm.loading},model:{value:(_vm.values.company_name),callback:function ($$v) {_vm.$set(_vm.values, "company_name", $$v)},expression:"values.company_name"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"address","rules":"required|address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"flex mb-2 text-gray-600"},[_vm._v("Work address")]),_c('AppTextArea',{attrs:{"errors":errors},model:{value:(_vm.values.address),callback:function ($$v) {_vm.$set(_vm.values, "address", $$v)},expression:"values.address"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"monthly_income","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"flex mb-2 text-gray-600"},[_vm._v("Monthly income")]),_c('AppInput',{staticClass:"w-full",attrs:{"type":"number","errors":errors,"disabled":_vm.loading},model:{value:(_vm.values.monthly_income),callback:function ($$v) {_vm.$set(_vm.values, "monthly_income", $$v)},expression:"values.monthly_income"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"work_sector","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"flex mb-2 text-gray-600"},[_vm._v("Work sector")]),_c('AppSelect',{staticClass:"w-full",attrs:{"errors":errors,"disabled":_vm.loading},model:{value:(_vm.values.work_sector),callback:function ($$v) {_vm.$set(_vm.values, "work_sector", $$v)},expression:"values.work_sector"}},[_c('option',{attrs:{"disabled":"","selected":""}},[_vm._v("Select work sector")]),_vm._l((_vm.sectors),function(sector){return _c('option',{key:sector.value,domProps:{"value":sector.value}},[_vm._v(_vm._s(sector.text))])})],2)]}}])}),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('ValidationProvider',{attrs:{"name":"start_month","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"flex mb-2 text-gray-600"},[_vm._v("Start month")]),_c('AppSelect',{staticClass:"w-full",attrs:{"errors":errors,"disabled":_vm.loading},model:{value:(_vm.values.start_month),callback:function ($$v) {_vm.$set(_vm.values, "start_month", $$v)},expression:"values.start_month"}},[_c('option',{attrs:{"disabled":"","selected":""}},[_vm._v("Start month")]),_vm._l((_vm.months),function(month){return _c('option',{key:month.value,domProps:{"value":month.value}},[_vm._v(_vm._s(month.text))])})],2)]}}])}),_c('ValidationProvider',{attrs:{"name":"start_year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"flex mb-2 text-gray-600"},[_vm._v("Start year")]),_c('AppSelect',{staticClass:"w-full",attrs:{"errors":errors,"disabled":_vm.loading},model:{value:(_vm.values.start_year),callback:function ($$v) {_vm.$set(_vm.values, "start_year", $$v)},expression:"values.start_year"}},[_c('option',{attrs:{"disabled":"","selected":""}},[_vm._v("Start year")]),_vm._l((_vm.years),function(year){return _c('option',{key:year.value,domProps:{"value":year.value}},[_vm._v(_vm._s(year.text))])})],2)]}}])})],1)],1),_c('div',{staticClass:"flex mt-10"},[_c('AppButton',{attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Next")])],1)])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }