<template>
  <button
      @click="$emit('click')" class="inline-block bg-blue-700 text-white rounded-lg focus:ring focus:ring-primary-100 focus:outline-none"
      :class="{ 'p-4 py-1': size === 'md', 'py-1 px-4': size === 'sm', 'py-3 px-8': size === 'lg', 'loading': loading, 'opacity-50 cursor-not-allowed' : disabled }"
      :type="type" :disabled="disabled"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "AppButton",
  props: {
    to: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      options: ['sm', 'md', 'lg'],
      default: 'md'
    },
    type: {
      type: String,
      default: 'button',
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
