<template>
  <div class="py-14 my-auto">
    <template v-if="$store.state.stage > 1">
      <SectionHeader title="Welcome back" subtitle="Continue your loan request"/>
      <div class="flex mt-10">
        <AppButton @click="$emit('done')">Continue</AppButton>
        <button @click="cancel()" class="py-1 px-3 border border-red-700 rounded-lg text-red-700 text-sm ml-2">
          Cancel
        </button>
      </div>
    </template>
    <template v-else>
      <SectionHeader title="Our partner banks will like to fund you"/>
      <AppCheckbox class="-mt-5" v-model="accepted">I agree to share data that helps me get credit</AppCheckbox>
      <div class="flex mt-10">
        <AppButton :disabled="!accepted" @click="$emit('done')">Continue</AppButton>
        <button @click="cancel()" class="py-1 px-3 border border-red-700 rounded-lg text-red-700 text-sm ml-2">
          Cancel
        </button>
      </div>
    </template>
  </div>
</template>

<script>
	import AppButton from "@/components/global/Button";
	import SectionHeader from "@/components/global/SectionHeader";
	import AppCheckbox from "@/components/global/AppCheckbox";

	export default {
		name: "Preload",
		components: { AppCheckbox, SectionHeader, AppButton },
		data: () => ({
			accepted: false
		}),
		methods: {
			cancel() {
				const r = confirm("Are you sure you want to cancel?");
				if (r === true) {
					parent.postMessage({ id: 'cclan-cancel' }, "*");
				}
			},
		}
	}
</script>
