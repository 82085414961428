<template>
  <div class="h-full flex flex-col">
    <div v-if="loading" class="my-auto">
      <Loader :text="loading"/>
    </div>
    <div v-else class="py-14 my-auto">
      <Error v-if="error" :text="error" show-retry @retry="loadQuestions()"/>
      <template v-else>
        <template v-if="currentQuestion">
          <SectionHeader :title="currentQuestion.question" :subtitle="currentQuestion.rider"/>
          <div class="flex flex-col gap-3">
            <div
                @click="chooseAnswer(answer)"
                v-for="(answer, i) in currentQuestion.answers"
                :key="i"
                class="border px-5 py-3 cursor-pointer rounded-lg flex items-center justify-between"
            >
              {{ answer }}
              <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
            </div>
          </div>
          <QuestionsStatus/>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader";
import Loader from "@/components/global/Loader";
import ajax from "@/mixins/ajax";
import Error from "@/components/global/Error";
import QuestionsStatus from "./QuestionsStatus";

export default {
  name: "Questions",
  components: { QuestionsStatus, Error, Loader, SectionHeader },
  mixins: [ajax],
  data() {
    return {
      current: null,
    };
  },
  created() {
    if (!this.$store.state.config.show_questions) return this.$emit('done');
    if (!this.$store.state.timer_start) return this.$emit('done');
    this.loadQuestions();
  },
  computed: {
    allQuestions() {
      return this.$store.state.questions;
    },
    unAnsweredQuestions() {
      return this.allQuestions.filter(q => !q.user_answer);
    },
    currentQuestion() {
      return this.unAnsweredQuestions.find(q => q.id === this.current) || null;
    }
  },
  methods: {
    async loadQuestions() {
      this.setLoading('Preparing questions..');
      const { request_id, token } = this.$store.state;
      try {
        const { data } = await this.$http.post('/customer/getquestions', { token, request_id });
        const existing = this.$store.state.questions;
        const questions = [...existing, ...(data?.data?.questions || [])];
        this.$store.commit('patch', { questions });
        this.current = this.unAnsweredQuestions[0]?.id;
        if (!this.allQuestions.length) return this.$emit('done');
      } catch (e) {
        console.log({ e });
        this.setError(e.response ? e.response.data.message || 'Failed to get questions' : 'Failed to get questions');
      }
      this.setLoading('');
    },
    chooseAnswer(answer) {
      const questions = this.$store.state.questions.map(q => {
        if (q.id === this.current) {
          return { ...q, user_answer: answer[0] };
        }
        return q;
      });
      this.$store.commit('patch', { questions });
      const next = questions.find(q => !q.user_answer);
      if (next) {
        this.current = next.id;
      } else {
        this.$emit('done');
      }
    },
  }
}
</script>
