<template>
  <Loader v-if="loading" :text="loading" class="my-auto"/>
  <Error v-else-if="error" :text="error" class="my-auto"/>
  <div v-else class="my-auto">
    <SectionHeader title="We will like to set a mandate on this account" subtitle="Kindly upload a copy of signature attached to this account"/>

    <div @click="next()" class="border bg-gray-50 rounded p-4 cursor-pointer">
      <div class="text-gray-700 text-sm mb-1">{{ bank.name }}</div>
      <div class="text-gray-400">Account number: <span class="font-semibold text-gray-700">{{ account.account_number }}</span></div>
      <div class="text-gray-400">Account name: <span class="font-semibold text-gray-700">{{ account.account_name }}</span></div>
    </div>

    <label
        class="border-2 border-dashed rounded-xl w-full flex cursor-pointer overflow-hidden px-5 py-4 relative mt-4 hover:bg-gray-50"
        :class="{'p-0 w-48 h-48': preview}"
    >
      <span v-if="!file" class="flex items-center justify-center">
      <i class="fa fa-upload text-yellow-400 mr-3"></i>Upload signature
      </span>
      <img v-else :src="preview" alt="signature" class="absolute top-0 left-0 w-full h-full object-cover">
      <input type="file" name="file" id="file" hidden @change="handleFileChange" accept="image/png,image/jpeg,image/jpg"/>
    </label>

    <div v-if="file" class="overflow-hidden mt-2 truncate text-sm">{{ file.name }}</div>

    <AppButton v-if="preview" @click="upload()" :disabled="!preview" class="mt-10">Upload</AppButton>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader";
import AppButton from "@/components/global/Button";
import Loader from "@/components/global/Loader";
import Error from "@/components/global/Error";
import ajax from "@/mixins/ajax";

export default {
  name: "Signature",
  components: { Error, Loader, AppButton, SectionHeader },
  mixins: [ajax],
  data() {
    return {
      file: null,
      preview: null,
      account: this.$store.state.account,
      banks: []
    }
  },
  created() {
    const { has_data, account } = this.$store.state;
    if (has_data || !account.bank_id) return this.$emit('done');
    this.getBanks();
  },
  methods: {
    async handleFileChange(e) {
      this.file = e.target.files[0];
      this.preview = await this.convertToBase64(this.file);
    },
    next() {
      this.$emit('done');
    },
    async upload() {
      this.loading = 'Uploading signature..';
      try {
        const fd = new FormData();
        fd.append('file', this.file);
        const { data } = await this.$http.post('/customer/upload/picture', fd, { headers: { 'Content-Type': 'multipart/form-data' } });
        if (data.status) await this.submit(data.data.filename);
      } catch (e) {
        console.log(e);
      }
      this.loading = '';
    },
    async submit(url) {
      this.loading = 'Setting up mandate..';
      const { data } = await this.$http.post('/customer/account/mandate', {
        token: this.$store.state.token,
        signature: url
      });
      if (data.status) this.next();
      this.loading = '';
    },
    convertToBase64(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => resolve(reader.result), false);
        if (file) reader.readAsDataURL(file);
      })
    },
    async getBanks() {
      const res = await this.$http.get('https://mobile.creditclan.com/webapi/v1/banks_mono');
      this.banks = res.data.data;
    }
  },
  computed: {
    bank() {
      return this.banks.find(bank => bank.id === this.account.bank_id) || {};
    },
  }
}
</script>
