<template>
  <div class="flex flex-col h-full overflow-hidden">
    <QuestionsStatus/>
    <div class="flex flex-col h-full overflow-y-auto pb-32 pt-8">
      <div class="my-auto">
        <SectionHeader
          title="Provide numbers of people that trust you well"
          subtitle="Verification will be sent via WhatsApp, ensure you notify the owners of these numbers of incoming message from us"
        />

        <div class="flex flex-col gap-4 mt-6">
          <label v-for="i in count" :key="i">
            <AppInput placeholder="Enter phone number" v-model="numbers[i - 1].phone"/>
          </label>
        </div>

        <label class="flex items-center mt-6">
          <input v-model="confirmed" class="mr-2" type="checkbox"> I have notified them
        </label>

        <div class="flex mt-8">
          <AppButton @click="next()" :disabled="!valid || !confirmed">Continue</AppButton>
        </div>
        <p @click="$emit('done')" class="mt-4 text-gray-400 cursor-pointer">Skip</p>
      </div>
    </div>
  </div>
</template>

<script>
	import SectionHeader from "@/components/global/SectionHeader";
	import AppInput from "@/components/global/Input";
	import AppButton from "@/components/global/Button";
	import QuestionsStatus from "@/components/sections/QuestionsStatus";

	export default {
		name: "FrequentlyCalledNumbers",
		components: { AppButton, AppInput, SectionHeader, QuestionsStatus },
		data() {
			return {
				confirmed: false,
				numbers: JSON.parse(JSON.stringify([...this.$store.state.frequently_called_numbers])),
				count: Math.min(this.$store.state.config.no_frequently_called_numbers, 2)
			}
		},
		created() {
			if (this.$store.state.config.no_frequently_called_numbers < 1) return this.$emit('done');
			const { recently, twoWeeks, oneMonth, threeMonths } = this.$store.getters['lastApplied'];
			if (recently || twoWeeks) return this.$emit('done');
			else if (oneMonth || threeMonths) this.count = 1;
		},
		methods: {
			next() {
				if (!this.valid) return;
				this.$store.commit('patch', { frequently_called_numbers: this.numbers });
				this.$emit('done');
			}
		},
		computed: {
			valid() {
				const phone = this.$store.state.profile.phone;
				const cb = (n) => n.phone?.length === 11 && n.phone?.match(/(^[0][789]\d{9}$)/) && n.phone !== phone;
				return this.numbers.slice(0, this.count).every(cb) && this.numbers[0].phone !== this.numbers[1].phone;
			}
		}
	}
</script>
