export default {
	data() {
		return {
			loading: '',
			error: '',
			retryCb: null,
			backCb: null
		}
	},
	methods: {
		setError(error, reset) {
			if (reset) this.reset();
			this.error = error;
		},
		setLoading(loading, reset) {
			if (reset) this.reset();
			this.loading = loading;
		},
		reset() {
			this.loading = '';
			this.error = '';
			this.retryCb = null;
			this.backCb = null;
		},
		setRetry(cb) {
			this.retryCb = cb;
		},
		setBack(cb) {
			this.backCb = cb;
		},
		retry() {
			this.retryCb();
		},
		back() {
			this.backCb();
		}
	}
}
