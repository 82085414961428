<template>
  <div class="flex flex-col h-full overflow-hidden">
    <div class="flex flex-col h-full overflow-y-auto pb-32 pt-8">
      <div class="my-auto">
        <SectionHeader
            title="Provide work information"
            subtitle="Please fill in all fields correctly"
        />
        <form>
          <ValidationObserver ref="observer">
            <div class="flex flex-col space-y-4">
              <ValidationProvider name="occupation_id" rules="required" v-slot="{ errors }">
                <label class="flex mb-2 text-gray-600">Employment status</label>
                <AppSelect v-model="values.occupation_id" :errors="errors" class="w-full" :disabled="loading">
                  <option disabled selected>Select employment status</option>
                  <option :value="status.value" v-for="status in occupations" :key="status.value">
                    {{ status.text }}
                  </option>
                </AppSelect>
              </ValidationProvider>
              <ValidationProvider name="Place of work" rules="required" v-slot="{ errors }">
                <label class="flex mb-2 text-gray-600">Place of work</label>
                <AppInput
                    v-model="values.company_name" type="text" :errors="errors" class="w-full"
                    :disabled="loading"
                />
              </ValidationProvider>
              <ValidationProvider name="address" rules="required|address" v-slot="{ errors }">
                <label class="flex mb-2 text-gray-600">Work address</label>
                <AppTextArea v-model="values.address" :errors="errors"/>
              </ValidationProvider>
              <ValidationProvider name="monthly_income" rules="required" v-slot="{ errors }">
                <label class="flex mb-2 text-gray-600">Monthly income</label>
                <AppInput
                    v-model="values.monthly_income" type="number" :errors="errors" class="w-full" :disabled="loading"
                />
              </ValidationProvider>
              <ValidationProvider name="work_sector" rules="required" v-slot="{ errors }">
                <label class="flex mb-2 text-gray-600">Work sector</label>
                <AppSelect v-model="values.work_sector" :errors="errors" class="w-full" :disabled="loading">
                  <option disabled selected>Select work sector</option>
                  <option :value="sector.value" v-for="sector in sectors" :key="sector.value">{{ sector.text }}</option>
                </AppSelect>
              </ValidationProvider>
              <div class="grid grid-cols-2 gap-4">
                <ValidationProvider name="start_month" rules="required" v-slot="{ errors }">
                  <label class="flex mb-2 text-gray-600">Start month</label>
                  <AppSelect v-model="values.start_month" :errors="errors" class="w-full" :disabled="loading">
                    <option disabled selected>Start month</option>
                    <option :value="month.value" v-for="month in months" :key="month.value">{{ month.text }}</option>
                  </AppSelect>
                </ValidationProvider>
                <ValidationProvider name="start_year" rules="required" v-slot="{ errors }">
                  <label class="flex mb-2 text-gray-600">Start year</label>
                  <AppSelect v-model="values.start_year" :errors="errors" class="w-full" :disabled="loading">
                    <option disabled selected>Start year</option>
                    <option :value="year.value" v-for="year in years" :key="year.value">{{ year.text }}</option>
                  </AppSelect>
                </ValidationProvider>
              </div>
            </div>

            <div class="flex mt-10">
              <AppButton @click="submit()" :loading="loading">Next</AppButton>
            </div>
          </ValidationObserver>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader";
import AppSelect from "@/components/global/Select";
import AppInput from "@/components/global/Input";
import AppTextArea from "@/components/global/TextArea";
import AppButton from "@/components/global/Button";

const sectors = [
  { value: "61", text: "Agriculture" },
  { value: "11", text: "Banking" },
  { value: "29", text: "Business Owner / Enterprenuer" },
  { value: "31", text: "Civil Servant" },
  { value: "43", text: "Construction" },
  { value: "48", text: "Education" },
  { value: "65", text: "Energy" },
  { value: "59", text: "Financial Services" },
  { value: "60", text: "FMCG" },
  { value: "46", text: "Franchising" },
  { value: "44", text: "Gambling" },
  { value: "69", text: "General Commerce" },
  { value: "12", text: "Government" },
  { value: "52", text: "Healthcare" },
  { value: "50", text: "Hospitality / Tourism" },
  { value: "14", text: "Information Technology" },
  { value: "58", text: "Insurance" },
  { value: "30", text: "Legal Services" },
  { value: "51", text: "Mass Media" },
  { value: "64", text: "Mining & Quarying" },
  { value: "71", text: "Non Governmental Organization(NGOs)" },
  { value: "35", text: "Others" },
  { value: "49", text: "Pharmaceuticals" },
  { value: "34", text: "Principal / Head Teacher" },
  { value: "32", text: "Public Servant" },
  { value: "68", text: "Public Utilities" },
  { value: "47", text: "Real Estate" },
  { value: "70", text: "Religious Organization" },
  { value: "45", text: "Retail Sales" },
  { value: "33", text: "Teacher" },
  { value: "13", text: "Telecommunications" },
  { value: "63", text: "Tourism" },
  { value: "62", text: "Transport & Logistics" },
  { value: "55", text: "Waste Disposal" }
];

const months = [
  { value: '1', text: 'January' },
  { value: '2', text: 'February' },
  { value: '3', text: 'March' },
  { value: '4', text: 'April' },
  { value: '5', text: 'May' },
  { value: '6', text: 'June' },
  { value: '7', text: 'July' },
  { value: '8', text: 'August' },
  { value: '9', text: 'September' },
  { value: '10', text: 'October' },
  { value: '11', text: 'November' },
  { value: '12', text: 'December' }
];

const occupations = [
  { value: "1", text: "Employed" },
  { value: "4", text: "Employer" },
  { value: "2", text: "Entrepreneur" },
  { value: "20", text: "Retired" },
  { value: "5", text: "Self Employed" },
  { value: "3", text: "Student" },
  { value: "7", text: "Unemployed" }
];

const years = new Array(80).fill(null).map((_, i) => {
  const year = `${ (new Date()).getFullYear() - i }`;
  return { value: year, text: year };
});

export default {
  name: "WorkInformation",
  components: { AppButton, AppTextArea, AppInput, AppSelect, SectionHeader },
  data() {
    return {
      loading: false,
      values: {
        work_sector: '',
        occupation_id: '',
        address: '',
        monthly_income: '',
        start_month: '',
        start_year: '',
        company_name: ''
      },
      sectors,
      months,
      years,
      occupations
    }
  },
  created() {
    const { show_work_information } = this.$store.state.config;
    if (!show_work_information) return this.$emit('done');
    this.values = this.$store.state.work;
  },
  methods: {
    async submit() {
      if (!(await this.$refs.observer.validate())) return;
      this.$store.commit("work", this.values);
      this.$emit("done");
    }
  }
}
</script>
