<template>
  <label class="flex flex-col items-start">
    <select
        class="border border-gray-300 rounded-lg w-full focus:outline-none focus:ring" :disabled="disabled"
        :class="{ 'py-1 px-2': size === 'sm', 'py-3 px-4 text-md': size === 'md', 'opacity-50' : disabled }"
        v-model="model" @change="$emit('change', $event)"
    >
      <slot></slot>
    </select>
    <small class="text-red-500 mt-2" v-for="error in errors" :key="error">{{ error }}</small>
  </label>
</template>

<script>
export default {
  name: "AppSelect",
  props: {
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      options: ['sm', 'lg'],
      default: 'md'
    },
    errors: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
