import Vue from 'vue';
import App from './App.vue';
import store from './store';
import './assets/tailwind.scss';
import './assets/global.scss';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import http from './plugins/http';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, length, max, required } from 'vee-validate/dist/rules';
import { formatCurrency } from "@/lib/utils";
import Flutterwave from 'flutterwave-vue-v3';
import EventBus from './lib/event-bus';

extend('required', {
	...required,
	message: 'This field is required'
});

extend('max', {
	...max,
	message: '{_field_} may not be greater than {length} characters',
});

extend('length', {
	...length,
	message: '{_field_} must be exactly {length} characters',
});

extend('email', {
	...email,
	message: 'Email must be valid',
});

extend('phone', {
	validate: value => /^[0]\d{10}$/.test(value),
	message: 'Please enter a valid phone number'
});

extend('address', {
	validate: value => /\w+( [^\s]+){2,}$/.test(value),
	message: 'Please enter a valid address'
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.use(Flutterwave, { publicKey: 'FLWPUBK_TEST-b42ceac68c20586de511ddc547734bbf-X' });

Vue.use(VueAwesomeSwiper);

Vue.config.productionTip = false;

Vue.prototype.$http = http;

Vue.prototype.$bus = EventBus;

Vue.filter('currency', (value) => formatCurrency(value));

new Vue({
	store,
	render: h => h(App)
}).$mount('#app');
