<template>
  <paystack
      :amount="nairaToKobo(amount)"
      :email="email"
      :paystackkey="'pk_live_bb2a3031e6893b1bf3ce4f1b880e5c0fc5e3230d'"
      :reference="generateReference()"
      :callback="callback"
      :close="close"
      :embed="false"
  >
    <slot></slot>
  </paystack>
</template>

<script>
import paystack from 'vue-paystack';

export default {
  name: "Paystack",
  components: {
    paystack
  },
  props: {
    amount: {
      type: Number,
      required: true
    },
    email: {
      type: String,
      required: true
    }
  },
  methods: {
    generateReference() {
      let text = "";
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 10; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },
    callback: function (response) {
      this.$emit('done', response);
    },
    close: function () {
      console.log("Payment closed")
    },
    nairaToKobo(n) {
      return n * 100
    }
  },
}
</script>
