<template>
  <div v-if="overlay" class="my-auto w-full">
    <RandomQuestions @done="overlay = ''"/>
  </div>
  <div v-else>
    <!-- Loading -->
    <Loader v-if="loading" :text="loading"/>
    <div class="w-full flex flex-col" v-else>
      <!-- Upload -->
      <template v-show="currentView === 'upload'">
        <SectionHeader title="Upload bank statement"/>
        <Message v-if="error" variant="error" class="mb-6">{{ error }}</Message>
        <label class="w-full py-3 px-6 border-2 border-dotted bg-gray-50 text-gray-600 rounded-lg">
          <input type="file" hidden name="file" accept=".pdf" @change="handleFileChange($event)">
          <template v-if="!file">
            <i class="fa fa-upload mr-2"></i> Click to select pdf file
          </template>
          <template v-else>
            <i class="fa fa-check mr-2 text-green-500"></i> {{ file.name }}
          </template>
        </label>

        <form class="mt-7">
          <AppInput placeholder="Password" v-model="values.password"/>
          <small class="flex items-center leading-tight bg-yellow-50 rounded-lg py-3 px-4 text-yellow-600 mt-4 border border-yellow-100">
            <i class="fa fa-exclamation-circle mr-2"></i> Enter password above if pdf file is secured, otherwise, leave password field empty
          </small>

          <AppSelect v-model="values.type" class="mt-4">
            <option value="" selected disabled>Select statement type</option>
            <option v-for="type in types" :value="type.id" :key="type.id">{{ type.name }}</option>
          </AppSelect>

          <div class="flex mt-6">
            <AppButton :disabled="!file || !values.type" @click="upload()">Analyse</AppButton>
          </div>
        </form>
        <p class="mt-10 relative text-sm text-yellow-400"><i class="fa fa-bolt mr-2"></i>
          Powered by Creditclan
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import banks from "@/lib/banks";
import Loader from "@/components/global/Loader";
import AppInput from "@/components/global/Input";
import AppSelect from "@/components/global/Select";
import AppButton from "@/components/global/Button";
import SectionHeader from "@/components/global/SectionHeader";
import view from '@/mixins/view';
import RandomQuestions from "@/components/sections/RandomQuestions";
import Message from "../global/Message";

export default {
  name: "BSLocal",
  components: { Message, RandomQuestions, SectionHeader, AppButton, AppSelect, AppInput, Loader },
  mixins: [view],
  data() {
    return {
      types: [
        { id: '1', name: 'Personal' },
        { id: '2', name: 'Business' },
      ],
      banks: banks,
      currentView: 'upload',
      loading: false,
      file: null,
      values: {
        password: '',
        type: '',
      },
      error: '',
      url: null
    };
  },
  methods: {
    showError(error) {
      this.error = error;
    },
    handleFileChange(e) {
      this.values.password = '';
      this.file = e.target.files[0];
    },
    upload() {
      this.submit();
    },
    async submit() {
      this.overlay = 'random-questions';
      this.loading = 'Processing bank statement..';
      const payload = { statement: this.file, ...this.values, full_name: this.$store.state.profile.full_name };
      const fd = new FormData();
      Object.keys(payload).forEach(key => fd.append(key, payload[key]));
      try {
        const { data } = await this.$http.post('https://app.bankstatement.ai/analytics/widget', fd);
        if (data.transaction_id) {
          this.saveType(data.transaction_id, payload.type);
          await this.checkStatus(data.transaction_id);
        } else this.showError('Something went wrong, please check pdf file or try again');
      } catch (err) {
        this.showError(err.response ? err.response.data.message : 'An error occurred, please try again');
      }
      this.loading = '';
    },
    saveType(transaction_id, statement_type) {
      const fd = new FormData();
      fd.append('transaction_id', transaction_id);
      fd.append('statement_type', statement_type);
      this.$http.post('https://view.bankstatement.ai/index.php/analysis/statementType', fd);
    },
    async checkStatus(transaction_id, n = 0) {
      if (n === 10) return this.showError('Something went wrong, please check pdf file or try again');
      try {
        const { data } = await this.$http.get(`https://app.bankstatement.ai/analytics/status/${ transaction_id }`);
        const { analytics_pdf, extraction_error, name_check } = data;
        if (extraction_error) return this.showError(extraction_error);
        if (analytics_pdf) {
          if (!name_check.status) {
            this.setLoading('');
            return this.showError('Sorry, this statement belongs to someone else');
          }
          return this.$emit('done', transaction_id);
        }
        setTimeout(() => this.checkStatus(transaction_id, ++n), 2000);
      } catch (err) {
        this.showError(err.response.data.error);
      }
    },
  },
}
</script>
