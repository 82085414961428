<template>
  <div class="h-screen md:p-4 lg:p-12 bg-gray-900 bg-opacity-70">
    <div class="grid grid-cols-12 overflow-hidden h-full max-w-7xl mx-auto md:rounded-lg lg:rounded-xl">
      <div
          class="hidden md:flex lg:flex md:col-span-3 lg:col-span-5 bg-blue-600"
          :style="{ background: `linear-gradient(to right, rgb(58 75 168 / 60%), rgb(160 66 157 / 60%)), url(${banner})` }"
      />
      <div class="col-span-12 md:col-span-9 lg:col-span-7 flex flex-col bg-white relative overflow-hidden">
        <img src="@/assets/images/pattern-up.png" alt="background pattern" width="300" class="absolute top-0 left-0"/>
        <img
            src="@/assets/images/pattern-down.png" alt="background pattern" width="300"
            class="absolute bottom-0 right-0"
        />
        <div class="h-full overflow-y-auto p-6 lg:py-10 lg:px-12 flex flex-col z-50">
          <Preload v-if="preload" @done="preloaded()"/>
          <template v-else>
            <Loader v-if="loading" class="my-auto" :text="loading"/>
            <Error v-else-if="error" :text="error" class="my-auto" show-retry @retry="init()"/>
            <Analysis v-else-if="$store.state.profile.user_id"/>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/global/Loader";
import Analysis from "@/views/Analysis";
import banks from "@/lib/banks";
import ajax from "@/mixins/ajax";
import Error from "@/components/global/Error";
import Preload from "@/components/sections/Preload";
import { decodeToken } from "./lib/utils";

export default {
  components: { Preload, Error, Analysis, Loader },
  mixins: [ajax],
  data() {
    return {
      loading: false,
      banner: '',
      data: null,
      preload: false
    }
  },
  created() {
    this.setup();
  },
  mounted() {
    parent.postMessage({ id: 'cclan-frame-loaded' }, '*');
  },
  methods: {
    setup() {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');
      if (!token) return this.setError('Something went wrong');
      const decoded = decodeToken(token);
      const {
        request, profile, stage = 0, request_id, home_address = { address: '', state: '', lga: '' },
        config: {
          tokenize_card = true,
          analyze_bank_statement = false,
          no_frequently_called_numbers = 2,
          show_offers = true,
          show_questions = false,
          show_bank_account = true,
          match_account_with_bvn = false,
          show_work_information = false,
          notify_whatsapp = false
        } = {},
        extra = {}, banner
      } = decoded;
      this.banner = banner || 'https://images.unsplash.com/photo-1572666341285-c8cb9790ca50?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80';
      this.$store.commit('patch', {
        request: { ...request, tenor_type: 2 },
        profile,
        home_address,
        stage,
        request_id,
        extra
      });
      this.$store.commit('config', {
        tokenize_card,
        analyze_bank_statement,
        no_frequently_called_numbers,
        show_offers,
        show_questions,
        show_bank_account,
        match_account_with_bvn,
        show_work_information,
        notify_whatsapp
      });
      this.data = { profile: { ...profile, ...home_address }, request };
      this.init();
      this.preload = true;
    },
    preloaded() {
      this.preload = false;
    },
    async init() {
      try {
        this.setLoading('Just a moment..');
        let data = {};
        data = await this.checkUser(this.data.profile.email, this.data.profile.phone);
        if (!data.user_id && !this.error) data = await this.createUser(this.data.profile);
        if (data.user_id && data.token) {
          this.$store.commit('patch', { token: data.token });
          this.$store.commit('profile', { user_id: data.user_id, ...this.data.profile });
          await this.getUserDetails(data.token);
          this.$store.commit('patch', { request: this.data.request });
          if (!this.$store.state.has_data) this.$store.dispatch('postData', { type: 'phone' });
          if (this.$store.state.config.show_questions) await this.getRandomQuestions();
        }
        this.setLoading('');
      } catch (e) {
        this.setError('An error occurred, please try again!', true)
      }
    },
    async getRandomQuestions() {
      const { token, request_id } = this.$store.state;
      const res = await this.$http.post('/customer/getrandomquestions', {
        token, request_id
      });
      this.$store.commit('patch', { questions: res.data.data.questions });
    },
    async checkUser(email, phone) {
      try {
        const { data } = await this.$http.post(`/customer/check/details`, { email, phone });
        const { token, user_id } = data;
        return { token, user_id };
      } catch (e) {
        this.setError('An error occurred, please try again!');
        return false;
      }
    },
    async createUser(data) {
      try {
        const payload = { profile: { ...data, password: 'temp', is_individual: 1 } };
        const res = await this.$http.post('/customer/register/user', payload);
        this.$store.commit('patch', { new_customer: true });
        const { token, user_id } = res.data;
        return { token, user_id };
      } catch (e) {
        this.setError('An error occurred, please try again!');
        return false;
      }
    },
    async getUserDetails(token) {
      try {
        const res = await this.$http.post('/user/detailsbyid', { token });
        const {
          profile: { file_name, phone, legal_name: full_name },
          bvn: { bvn },
          work: { net_monthly_income, company_name, work_sector, start_month, start_year, occupation_id },
          accounts
        } = res.data.data.userData.data;
        // eslint-disable-next-line no-unused-vars
        const { has_data, last_application_date, frequently_called_numbers: fcn } = res.data;
        const profile_image = this.$store.state.profile.profile_image || file_name;
        this.$store.commit('profile', { profile_image, phone, full_name });
        this.$store.commit('account', { bvn });
        const frequently_called_numbers = fcn || [{ phone: '' }, { phone: '' }]
        this.$store.commit('patch', {
          work: {
            monthly_income: net_monthly_income,
            company_name,
            work_sector,
            start_month,
            start_year,
            occupation_id
          },
          has_data, last_application_date: null, frequently_called_numbers
        });
        if ((accounts || []).length === 0) this.$store.commit('patch', { new_customer: true });
        else {
          const {
            bank_id,
            card_name: account_name,
            last_four_digits: account_number,
            okra_id
          } = accounts[accounts.length - 1];
          const bank_code = banks.find(bank => bank.id === bank_id)?.bank_code;
          const account = { bank_id, account_name, account_number, bvn, bank_code };
          this.$store.commit('account', { ...account });
          this.$store.commit('patch', { okra_id });
        }
      } catch (e) {
        this.setError('Failed to get user details, please try again');
      }
    },
  }
}
</script>
