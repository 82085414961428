<template>
  <label class="flex items-center">
    <input type="checkbox" v-model="model"/>
    <span class="ml-3">
      <slot></slot>
    </span>
  </label>
</template>

<script>
export default {
  name: "AppCheckbox",
  props: {
    value: {
      type: Boolean,
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
