<template>
  <div v-if="!hide" class="absolute bottom-0 left-0 w-full py-6 px-8 md:px-14 bg-red-50 z-50">
    <div class="text-base font-semibold text-gray-700">
      Time Left: {{ timer.minutes }}:{{ timer.seconds }}
    </div>
    <div class="relative mt-3">
      <div class="overflow-hidden h-2 text-xs flex rounded bg-red-200">
        <div
            :style="{ width: `${percentage}%` }"
            class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500 transition-all duration-500 ease-in-out"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { addSeconds, intervalToDuration } from 'date-fns';

export default {
  name: "QuestionsStatus",
  data() {
    return {
      timer: {},
      interval: null,
      audio: null,
      hide: !this.$store.state.config.show_questions || !this.$store.state.questions.length
    };
  },
  created() {
    if (this.hide) return;
    // this.audio = new Audio(`http://www.x-3-x.net/vrml/archive/cache/jeffreykbedrick.com/3d/office/ticktock.wav`);
    this.initTimer();
  },
  methods: {
    initTimer() {
      this.getTime();
      this.interval = setInterval(() => this.getTime(), 1000);
    },
    getTime() {
      const { timer_start, timer_end } = this.$store.state;
      if (!timer_start || !timer_end) return this.clearTimer();
      const start = addSeconds(timer_start, 1);
      this.updateTime(start, timer_end);
    },
    updateTime(start, end) {
      this.$store.commit('patch', { timer_start: start });
      this.timer = intervalToDuration({ start, end });
      if (this.audio) this.audio.play();
      if (+this.timer.minutes === 0 && +this.timer.seconds === 0) {
        this.$store.commit('patch', { timer_start: null, timer_end: null });
        this.clearTimer();
        this.$bus.$emit('time-finished');
      }
    },
    clearTimer() {
      if (this.interval) clearInterval(this.interval);
    }
  },
  beforeDestroy() {
    this.clearTimer();
  },
  computed: {
    questions() {
      const views = [
        "home_address",
        "social_handles",
        "frequently_called_number",
        "monthly_income",
      ];
      const { questions } = this.$store.state;
      return questions.length + views.length;
    },
    answered() {
      let completed = 0;
      const state = this.$store.state;
      const { recently, twoWeeks, oneMonth } = this.$store.getters["lastApplied"];
      if (recently || twoWeeks || oneMonth || state.home_address.address) completed++;
      if (recently || twoWeeks || oneMonth || state.social.twitter) completed++;
      if (recently || twoWeeks || state.frequently_called_numbers[0].phone) completed++;
      if (state.work.monthly_income) completed++;
      const questions = state.questions.filter((q) => q.user_answer);
      return questions.length + completed;
    },
    percentage() {
      return (this.answered / this.questions) * 100;
    },
  },
};
</script>
