<template>
  <label class="flex flex-col items-start">
    <textarea
        class="py-3 px-4 text-md border border-gray-300 bg-white rounded-lg w-full focus:outline-none focus:ring"
        :placeholder="placeholder" :class="{ 'opacity-50' : disabled }" :disabled="disabled"
        v-model="model"
    ></textarea>
    <small class="text-red-500 mt-2" v-for="error in errors" :key="error">{{ error }}</small>
  </label>
</template>

<script>
export default {
  name: "AppTextArea",
  props: {
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
