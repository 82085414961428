<template>
  <div class="py-14 my-auto">
    <div class="mb-10">
      <Avatar/>
    </div>
    <SectionHeader
        :title="`Welcome back, ${$store.state.profile.full_name.split(' ')[0]}`" subtitle="Let's quickly get you a checkout offer"
        spacing="1"
    />
    <div class="flex mt-12">
      <AppButton @click="$emit('done')" :disabled="!image">Continue</AppButton>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader";
import AppButton from "@/components/global/Button";
import Avatar from "@/components/Avatar";

export default {
  name: "Welcome",
  components: { Avatar, AppButton, SectionHeader },
  computed: {
    image() {
      const { profile_image } = this.$store.state.profile;
      if (profile_image === 'https://res.cloudinary.com/du1mqyww8/image/upload/v1617623098/wnwad3hebiais8ulr5yy.png') return null
      else return profile_image;
    }
  }

}
</script>
