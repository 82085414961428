<template>
  <div v-if="overlay" class="my-auto w-full">
    <RandomQuestions @done="overlay = ''"/>
  </div>
  <div v-else class="h-full flex flex-col">
    <div class="flex justify-between">
      <button
        @click="popView()" v-if="history.length > 1 && !loading"
        class="w-8 h-8 rounded-full text-gray-500 bg-gray-50 border text-sm flex items-center justify-center"
      >
        <i class="fa fa-chevron-left"></i>
      </button>
      <span v-else></span>
      <button @click="cancel()" class="py-1 px-3 border border-red-700 rounded-lg text-red-700 text-sm">
        Cancel
      </button>
    </div>

    <div class="h-full flex flex-col">
      <div class="my-auto">
        <Loader v-if="loading" class="my-auto" :text="loading"/>
        <Error v-else-if="error" :text="error" :show-retry="!!retryCb" @retry="retry()"/>
        <template v-else>
          <Welcome v-if="view === 'welcome'" @done="startChecks()"/>
          <Picture v-if="view === 'picture'" @done="startChecks()"/>
          <Modal v-if="modal === 'checks'">
            <RunChecks @done="checksDone()" @close="modal = ''"/>
          </Modal>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
	import RunChecks from "@/components/sections/RunChecks";
	import Picture from "@/components/sections/Picture";
	import Modal from "@/components/global/Modal";
	import Loader from "@/components/global/Loader";
	import view from "@/mixins/view";
	import Welcome from "@/components/sections/Welcome";
	import ajax from "@/mixins/ajax";
	import Error from "@/components/global/Error";
	import RandomQuestions from "@/components/sections/RandomQuestions";

	export default {
		name: "PreAnalysis",
		components: { RandomQuestions, Error, Welcome, Loader, Modal, Picture, RunChecks },
		mixins: [view, ajax],
		created() {
			if (this.$store.state.new_customer) this.pushView('picture');
			else this.pushView('welcome');
			this.$bus.$on('time-finished', () => {
				this.checksDone();
			});
		},
		methods: {
			startChecks() {
				if (this.$store.getters['lastApplied'].recently) this.checksDone()
				else this.modal = 'checks';
			},
			async checksDone() {
				this.modal = '';
				let { request_id } = this.$store.state;
				if (!request_id) request_id = await this.createRequest();
				if (request_id) await this.submitBsReport(request_id);
				if (request_id && !this.error) this.$emit('done');
			},
			async createRequest() {
				this.overlay = 'random-questions';
				this.setLoading('Submitting your request..');
				try {
					const { request, profile, frequently_called_numbers: fcn, account, work, home_address, extra } = this.$store.state;
					const { data } = await this.$http.post('/loan/apply', {
						profile, frequently_called_numbers: [...fcn].filter(n => n.phone), account, work, request, home_address, ...extra
					});
					if (!data.status) {
						this.setError(data.message || 'An error occurred while creating your request, please try again', true);
						this.setRetry(() => this.checksDone());
						return false
					}
					this.$store.commit('patch', { request_id: data.dd });
					parent.postMessage({ id: 'cclan-breakpoint', data: { breakpoint: 2, request_id: data.dd } }, '*');
					return data.dd;
				} catch (e) {
					this.setError(e.response ? e.response.data.message : e.message || 'An error occurred, please try again', true);
					this.setRetry(() => this.checksDone());
					return false;
				}
			},
			async submitBsReport(request_id) {
				try {
					const { bs: { has_online_banking, response }, account: { account_name, account_number, bank_id } } = this.$store.state;
					const { data: { token } } = await this.$http.post('https://mobile.creditclan.com/api/v3/bankstatement/initiate', {
						request_id, account: { account_number, bank_id, account_name, }, has_online_banking
					});
					await this.$http.post(`https://mobile.creditclan.com/api/v3/bankstatement/update`, { token, analyze: true, ...response });
				} catch (e) {
					this.setError(e.response ? e.response.data.message : e.message || 'An error occurred, please try again');
					this.setRetry(() => this.checksDone());
				}
				this.ajax = false;
			},
			cancel() {
				const r = confirm("Are you sure you want to cancel?");
				if (r === true) parent.postMessage({ id: 'cclan-cancel' }, "*");
			},
		}
	};
</script>
