<template>
  <div v-if="overlay" class="my-auto w-full">
    <RandomQuestions @done="overlay = ''"/>
  </div>
  <div v-else>
    <Loader v-if="loading" :text="loading"/>
    <Error v-else-if="error" :text="error" :show-retry="!!retryCb" @retry="retry()" show-back @back="$emit('back')"/>
    <template v-else>
      <Modal v-if="phoneMismatch.current">
        <div class="my-auto">
          <div class="text-gray-700 max-w-lg mb-8">
            <i class="fa fa-times text-red-500 mr-3"></i>
            {{ phoneMismatch.message }}. Enter correct phone number below to continue
          </div>
          <AppInput v-model="phoneMismatch.new" placeholder="Enter phone number"/>
          <div class="mt-10 flex flex-col">
            <AppButton @click="handlePhoneChange()" :disabled="phoneMismatch.new.length < 11">Continue</AppButton>
            <button @click="$emit('back')" class="text-gray-600 mt-3 text-sm">Upload bank statement file instead</button>
          </div>
        </div>
      </Modal>
      <form v-if="currentView === 'ticket'">
        <SectionHeader
            title="Verify ticket"
            subtitle="A ticket number and password has been sent to your phone number, enter information below to continue"
        />
        <ValidationObserver ref="observer">
          <div class="flex flex-col gap-4">
            <ValidationProvider name="ticketNo" rules="required" v-slot="{ errors }">
              <AppInput placeholder="Ticket number" v-model="values.ticketNo" :errors="errors"/>
            </ValidationProvider>
            <ValidationProvider name="password" rules="required" v-slot="{ errors }">
              <AppInput placeholder="Password" v-model="values.password" :errors="errors"/>
            </ValidationProvider>
          </div>
        </ValidationObserver>
        <div class="flex flex-col items-start mt-12">
          <AppButton @click="verifyTicket()">Next</AppButton>
          <button @click="$emit('back')" class="text-gray-600 mt-3 text-sm">Upload bank statement file instead</button>
        </div>
        <p class="mt-10 relative text-sm text-yellow-400"><i class="fa fa-bolt mr-2"></i>
          Powered by MBS
        </p>
      </form>
    </template>
  </div>
</template>

<script>
import banks from '@/lib/mbs-banks';
import AppInput from "@/components/global/Input";
import AppButton from "@/components/global/Button";
import SectionHeader from "@/components/global/SectionHeader";
import Loader from "@/components/global/Loader";
import Modal from "@/components/global/Modal";
import ajax from "@/mixins/ajax";
import Error from "@/components/global/Error";
import view from '@/mixins/view';
import { formatCurrency } from "@/lib/utils";
import RandomQuestions from "@/components/sections/RandomQuestions";

export default {
  name: "BSMbs",
  components: { RandomQuestions, Error, Modal, Loader, SectionHeader, AppButton, AppInput },
  mixins: [ajax, view],
  data() {
    return {
      data: null,
      setting: null,
      transaction_id: null,
      values: {
        ticketNo: '',
        password: ''
      },
      phoneMismatch: {
        current: false,
        message: '',
        new: ''
      },
      currentView: '',
      formatCurrency
    }
  },
  created() {
    this.overlay = 'random-questions';
    this.start();
  },
  methods: {
    handlePhoneChange: function () {
      this.phoneMismatch.current = false;
      this.phoneMismatch.message = '';
      this.start();
    },
    cancelPhoneChange: function () {
      this.phoneMismatch = { current: false, message: '', new: '' };
      this.$emit('back');
    },
    start: async function () {
      if (!this.settings) await this.getSettings();
      const { account: { bank_code, account_number, account_name }, profile: { phone } } = this.$store.state;
      const bankId = banks.find(bank => bank.sortCode === bank_code).id;
      const data = {
        destinationId: this.settings.clientid,
        accountNo: account_number,
        bankId,
        country: 'NG',
        startDate: this.formatDate(new Date(), 2),
        endDate: this.formatDate(new Date()),
        phone: this.phoneMismatch.new || phone,
        role: 'Applicant',
        username: 'soji.okunuga@creditclan.com',
        applicant: { name: account_name }
      };
      await this.initializeMBS(data);
    },
    async initializeMBS(payload) {
      try {
        this.setLoading('Initializing..');
        const { data } = await this.$http.post('https://app.bankstatement.ai/mbs/initialize', payload);
        this.transaction_id = data.transaction_id;
        await this.checkStatus();
      } catch (e) {
        this.setLoading('');
        this.$emit('back');
      }
    },
    checkStatus: async function (n = 1) {
      if (n === 7) {
        this.setLoading('')
        this.$emit('back');
      }
      try {
        this.setLoading(n <= 5 ? 'Checking..' : 'Hmm, taking longer than usual..');
        const { data } = await this.$http.get(`https://app.bankstatement.ai/mbs/status/${ this.transaction_id }`);
        if (data.status.match(/invalid/gi)) {
          if (data.feedback.includes('mismatched')) {
            this.phoneMismatch = { current: true, message: data.feedback, new: '' };
          } else {
            this.setError(data.feedback);
          }
        } else if (data.status.match(/error/gi)) {
          this.setError(data.feedback);
        } else if (data.status === 'Pending') {
          return setTimeout(() => this.checkStatus(++n), 5000);
        } else if (data.status === 'Ticket') {
          this.currentView = 'ticket';
        } else {
          this.setError(data.feedback);
        }
      } catch (e) {
        this.setError(e.message || 'An error occurred, please try again!');
      }
      if (this.error) this.setRetry(() => this.checkStatus());
      this.setLoading('');
    },
    verifyTicket: async function () {
      if (!await this.$refs.observer.validate()) return;
      this.loading = 'Verifying..';
      try {
        const { data } = await this.$http.post(`https://app.bankstatement.ai/mbs/confirm/${ this.transaction_id }`, this.values);
        if (!data.completed) this.showError(data.message);
        this.currentView = 'success';
        this.$emit('done', this.transaction_id);
      } catch (e) {
        this.setError(e.message);
        this.setRetry(() => this.verifyTicket());
      }
      this.setLoading('')
    },
    getSettings: async function () {
      this.loading = 'Please wait..';
      try {
        const { data } = await this.$http.get(`https://mobile.creditclan.com/api/v3/mbs/lender/settings`,);
        this.settings = data;
      } catch (e) {
        this.setError(e.message || 'An error occurred, please try again');
        this.setRetry(() => this.getSettings());
      }
      this.setLoading('')
    },
    formatDate(date, minus = 0) {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const month = months[date.getMonth() - minus < 0 ? 12 + (date.getMonth() - minus) : date.getMonth() - minus];
      const day = date.getDate();
      const year = date.getMonth() - minus < 0 ? date.getFullYear() - 1 : date.getFullYear();
      return `${ day }-${ month }-${ year }`;
    },
  }
}
</script>
