<template>
  <div class="flex flex-col h-full overflow-hidden">
    <QuestionsStatus/>
    <div class="flex flex-col h-full overflow-y-auto pb-32 pt-8">
      <div class="my-auto">
        <SectionHeader title="Select your monthly income range"/>
        <div class="grid grid-cols-2 gap-4">
          <div @click="setMonthlyIncome(25000)" class="px-3 py-2 text-base border rounded-lg cursor-pointer flex items-center justify-between">
            0 - 50k
            <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
          </div>
          <div @click="setMonthlyIncome(50000)" class="px-3 py-2 text-base border rounded-lg cursor-pointer flex items-center justify-between">
            50k - 100k
            <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
          </div>
          <div @click="setMonthlyIncome(100000)" class="px-3 py-2 text-base border rounded-lg cursor-pointer flex items-center justify-between">
            100k - 250k
            <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
          </div>
          <div @click="setMonthlyIncome(250000)" class="px-3 py-2 text-base border rounded-lg cursor-pointer flex items-center justify-between">
            250k - 500k
            <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
          </div>
          <div @click="setMonthlyIncome(500000)" class="px-3 py-2 text-base border rounded-lg cursor-pointer flex items-center justify-between">
            500k - 1m
            <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
          </div>
          <div @click="setMonthlyIncome(1000000)" class="px-3 py-2 text-base border rounded-lg cursor-pointer flex items-center justify-between">
            1m & above
            <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	import SectionHeader from "@/components/global/SectionHeader";
	import QuestionsStatus from "@/components/sections/QuestionsStatus";

	export default {
		name: "Income",
		components: { SectionHeader, QuestionsStatus },
		created() {
			const { recently, twoWeeks, oneMonth } = this.$store.getters['lastApplied'];
			if (recently || twoWeeks || oneMonth) return this.$emit('done');
		},
		data() {
			return {
				monthly_income: this.$store.state.work.monthly_income
			}
		},
		methods: {
			setMonthlyIncome(monthly_income) {
				this.$store.commit('work', { monthly_income });
				this.next();
			},
			next() {
				this.$emit('done');
			}
		}
	}
</script>
