<template>
  <div v-if="!allowed" class="my-auto">
    <SectionHeader title="We will like to connect with your bank to confirm, is this ok?"/>
    <div class="flex flex-col gap-3">
      <div @click="allowed = true" class="border px-5 py-3 cursor-pointer rounded-lg flex items-center justify-between">
        Yes
        <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
      </div>
      <div @click="$emit('cancel')" class="border px-5 py-3 cursor-pointer rounded-lg flex items-center justify-between">
        No
        <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
      </div>
    </div>
  </div>
  <div v-else class="my-auto">
    <template v-if="banks.length">
      <template v-if="view === 'accounts'">
        <SectionHeader title="What's your most used bank account?" subtitle="Select an account below to continue"/>
        <div @click="next()" class="border bg-gray-50 rounded p-4 cursor-pointer">
          <div class="text-gray-700 text-sm mb-1">{{ bank.name }}</div>
          <div class="text-gray-400">Account number: <span class="font-semibold text-gray-700">{{ values.account_number }}</span></div>
          <div class="text-gray-400">Account name: <span class="font-semibold text-gray-700">{{ values.account_name }}</span></div>
        </div>
        <p class="text-gray-600 mt-6">I now use
          <button class="text-blue-500" @click="change()">another account</button>
        </p>
      </template>
      <template v-if="view === 'add'">
        <SectionHeader
            title="Kindly help provide your most used bank account"
            subtitle="We will securely review your bank transactions to ensure you get the full credit you deserve"
        />
        <Message v-if="error" variant="error" class="mb-6">{{ error }}</Message>
        <form>
          <ValidationObserver ref="observer">
            <div class="flex flex-col gap-4">
              <ValidationProvider name="bank_code" rules="required" v-slot="{ errors }">
                <AppSelect v-model="values.bank_code" :errors="errors" class="w-full" :disabled="loading || validated">
                  <option disabled selected>Select bank</option>
                  <option :value="bank.bank_code" v-for="bank in banks" :key="bank.bank_id">{{ bank.name }}</option>
                </AppSelect>
              </ValidationProvider>
              <ValidationProvider name="account_number" rules="required" v-slot="{ errors }">
                <AppInput
                    v-model="values.account_number" type="number" placeholder="Account number" :errors="errors" class="w-full"
                    :disabled="loading || validated"
                />
              </ValidationProvider>
              <ValidationProvider name="bvn" rules="required|length:11" v-slot="{ errors }">
                <AppInput v-model="values.bvn" type="number" placeholder="BVN" :errors="errors" class="w-full" :disabled="loading || validated"/>
              </ValidationProvider>
            </div>

            <Message v-if="validated" variant="success" class="mt-6">Account name: {{ values.account_name }}</Message>

            <div class="flex mt-10">
              <AppButton v-if="!validated" @click="submit()" :loading="loading">Validate</AppButton>
              <template v-else>
                <AppButton @click="next()">Continue</AppButton>
                <button @click="change()" class="py-1 px-4 ml-2">Change</button>
              </template>
            </div>
          </ValidationObserver>
        </form>
      </template>
    </template>
    <Loader v-else text="Getting banks.." class="my-auto"/>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader";
import AppSelect from "@/components/global/Select";
import AppInput from "@/components/global/Input";
import AppButton from "@/components/global/Button";
import Message from "@/components/global/Message";
import Loader from "@/components/global/Loader";

export default {
  name: "BankAccount",
  components: { Loader, Message, AppButton, AppInput, AppSelect, SectionHeader },
  data() {
    return {
      allowed: true,
      values: this.$store.state.account,
      loading: false,
      error: '',
      bvnValid: false,
      view: '',
      banks: []
    }
  },
  created() {
    const { analyze_bank_statement, show_bank_account } = this.$store.state.config;
    if (!analyze_bank_statement) {
      if (!show_bank_account) return this.$emit('done');
    }
    const { recently, twoWeeks, oneMonth } = this.$store.getters['lastApplied'];
    if (recently || twoWeeks || oneMonth) return this.$emit('done');
    this.getBanks();
    const { new_customer, account: { account_number, bank_id } } = this.$store.state;
    if (account_number && bank_id && !new_customer) {
      this.allowed = true;
      this.view = 'accounts';
    } else {
      this.view = 'add';
    }
    if (this.values.bvn) this.bvnValid = true;
  },
  methods: {
    async submit() {
      this.error = '';
      this.bvnValid = false;
      this.values.account_name = null;
      const valid = await this.$refs.observer.validate()
      if (!valid) return;
      this.loading = true;
      await this.validateAccount();
      if (!this.values.account_name) {
        this.loading = false;
        return;
      }
      const { match_account_with_bvn } = this.$store.state.config;
      if (match_account_with_bvn) await this.validateBVN();
      if (match_account_with_bvn && this.bvnValid) {
        this.loading = false;
        return;
      }
      this.$store.commit('patch', { account: this.values });
      this.loading = false;
    },
    setError(error) {
      this.loading = false;
      this.error = error;
    },
    async validateAccount() {
      try {
        const payload = {
          bank_code: this.values.bank_code,
          account_number: this.values.account_number,
        };
        const { data } = await this.$http.post('https://mobile.creditclan.com/webapi/v1/account/resolve', payload);
        if (data.status !== "success") return this.setError('Could not validate account, Check your details and try again');
        this.values.bank_id = this.banks.find(bank => bank.bank_code === this.values.bank_code).id;
        this.values.account_name = data.data.account_name;
      } catch (error) {
        console.log({ error });
      }
    },
    async validateBVN() {
      const payload = {
        bvn: this.values.bvn,
        account_number: this.values.account_number,
        bank_code: this.values.bank_code,
        token: this.$store.state.token
      };
      const { data } = await this.$http.post('https://mobile.creditclan.com/api/v3/customer/validate_customer_bvn_account', payload);
      if (!data.status) return this.setError(data.message || 'Could not validate your bvn');
      if (!data.data.account_number) return this.setError('BVN & bank account do not match');
      if (!this.$store.state.has_data) await this.analyzeBVN();
      this.bvnValid = true;
    },
    async analyzeBVN() {
      try {
        await this.$store.dispatch('postData', { type: 'bvn' });
        const { data } = await this.$http.post('/customer/checkdata', { token: this.$store.state.token });
        this.$store.commit('patch', { has_data: data.data.has_data });
      } catch (e) {
        console.log({ e });
      }
    },
    change() {
      this.view = 'add'
      this.values.account_name = '';
      this.bvnValid = false;
    },
    next() {
      if (!this.values.account_name) return;
      this.$emit('done');
    },
    async getBanks() {
      const res = await this.$http.get('https://mobile.creditclan.com/webapi/v1/banks_mono');
      this.banks = res.data.data;
    }
  },
  computed: {
    validated() {
      const { match_account_with_bvn } = this.$store.state.config;
      return match_account_with_bvn ? !!this.values.account_name && !!this.bvnValid : !!this.values.account_name;
    },
    bank() {
      return this.banks.find(bank => bank.id === this.values.bank_id) || {};
    },
  }
}
</script>
