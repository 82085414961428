export const delay = (ms) => {
	return new Promise(resolve => setTimeout(resolve, ms));
}

export const roundToNearestHundred = (num) => {
	return Math.ceil(num / 100) * 100;
}

export const roundToNearestTen = (num) => {
	return Math.ceil(num / 10) * 10;
};

export const formatCurrency = (value) => {
	return `₦${ parseFloat(value).toLocaleString() }`
};

export const namesMatch = (a, b) => {
	if (typeof a !== "string" || typeof b !== "string") return false;
	return a.toLowerCase().split(' ').reduce((acc, curr) => {
		if (!acc) return acc;
		return b.toLowerCase().split(' ').includes(curr);
	}, true);
}

export const decodeToken = (token) => {
	const str = token.split('.').length > 1 ? token.split('.')[1] : token;
	return JSON.parse(atob(str));
}
