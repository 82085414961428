<template>
  <div v-if="overlay" class="py-14 my-auto w-full">
    <RandomQuestions @done="overlay = false"/>
    <!--    <template v-if="overlay === 'occupation'">-->
    <!--      <SectionHeader :title="`Are you a ${ getOccupation() }?`"/>-->
    <!--      <div class="flex flex-col gap-3">-->
    <!--        <div @click="completed = true" class="border px-5 py-3 cursor-pointer rounded-lg flex items-center justify-between">-->
    <!--          Yes-->
    <!--          <i class="fa fa-long-arrow-alt-right text-gray-500"></i>-->
    <!--        </div>-->
    <!--        <div @click="completed = true" class="border px-5 py-3 cursor-pointer rounded-lg flex items-center justify-between">-->
    <!--          No-->
    <!--          <i class="fa fa-long-arrow-alt-right text-gray-500"></i>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </template>-->
  </div>

  <div v-else class="py-14 my-auto">
    <template v-if="loading">
      <Loader
          v-if="loading === 'confirming'" text="Confirming your alternate number"
          subtext="We love to go on these errands. We are definitely here for you"
      />
      <Loader v-else-if="loading === 'checking'" text="Checking number.."/>
      <Loader v-else-if="loading === 'hold-up'" text="Crossing ojuelegba bridge, no cars are stopping...pim..pim"/>
      <Loader v-else :text="loading"/>
    </template>

    <template v-else>
      <template v-if="currentView === 'default'">
        <SectionHeader
            :title="`Looks like ${$store.state.profile.phone} is not your primary number`"
            subtitle="Don't worry, just provide your other number"
        />
        <Message v-if="error" variant="error" class="mb-6">{{ `${ error }` }}</Message>
        <label>
          <AppInput type="number" v-model="number" placeholder="eg: 08032938293"/>
        </label>
        <div class="flex mt-12">
          <AppButton @click="submit()" :disabled="!number || !tries">Continue</AppButton>
        </div>
        <p class="mt-5 text-gray-500">
          Alternatively, just give us your <span @click="$emit('view', 'nin')" class="text-blue-700 cursor-pointer">NIN</span>
          or Driver's license
        </p>
        <p @click="next()" class="mt-2 text-gray-400 cursor-pointer">Skip</p>
      </template>

      <template v-if="currentView === 'verify'">
        <SectionHeader
            :title="`Someone else is using ${number} on our platform`"
            :subtitle="`Enter the verification code sent to ${email} to verify that this number belongs to you`"
        />
        <Message v-if="error" variant="error" class="mb-6">{{ error }}</Message>
        <label>
          <AppInput v-model="code" placeholder="Enter code"/>
        </label>
        <div class="flex mt-12">
          <AppButton @click="verifyCode()" :disabled="!code">Continue</AppButton>
        </div>
        <p class="mt-4 text-gray-500">Not your number? <span @click="change()" class="text-blue-600 cursor-pointer">Change number</span></p>
      </template>
    </template>
    <img v-if="!loading" src="@/assets/images/sad.svg" alt="shield" class="w-20 mt-16 ml-auto"/>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader"
import AppButton from "@/components/global/Button";
import AppInput from "@/components/global/Input";
import Message from "@/components/global/Message";
import Loader from "@/components/global/Loader";
import { namesMatch } from "@/lib/utils";
import view from '@/mixins/view';
import ajax from "@/mixins/ajax";
import RandomQuestions from "@/components/sections/RandomQuestions";

export default {
  name: 'NoRecordsFound',
  components: { RandomQuestions, Loader, Message, AppInput, AppButton, SectionHeader },
  mixins: [view, ajax],
  data() {
    return {
      number: '',
      code: '',
      tries: 2,
      email: '',
      numbers: [],
      currentView: 'default'
    }
  },
  methods: {
    async submit() {
      if (!this.number || !this.tries) return;
      if (this.numbers.includes(this.number)) return this.setError(`You've already tried that number`);
      this.tries--
      this.overlay = 'random-questions';
      this.setLoading('confirming', true);
      if (!this.completed) this.overlay = 'occupation';
      try {
        const res = await this.$store.dispatch('postData', { type: 'phone', phone: this.number });
        if (res.error_code === '01') {
          this.email = res.email;
          this.currentView = 'verify';
          this.loading = '';
          return;
        }
        await this.checkData();
      } catch (e) {
        this.setError('An error occurred, please try again');
      }
      this.setLoading('');
    },
    async checkData() {
      this.setLoading('confirming');
      try {
        const { data } = await this.$http.post('/customer/checkdata', { token: this.$store.state.token });
        this.$store.commit('patch', { has_data: data.data.has_data });
        if (data.data.has_data) {
          if (namesMatch(this.$store.state.profile.full_name, data.data.name)) return this.next();
        }
        this.$emit('view', 'nin');
      } catch (e) {
        this.setError('An error occurred, please try again');
      }
      this.setLoading('');
    },
    async verifyCode() {
      this.overlay = 'random-questions';
      this.setLoading('Please wait..');
      try {
        const { data } = await this.$http.post('/customer/validate_email_otp', {
          otp: this.code,
          token: this.$store.state.token,
          amount: this.$store.state.request.amount,
          phone: this.number,
          type: 'phone'
        });
        if (!data.status) return this.setError(data.message || 'An error occurred, please try again');
        await this.checkData();
        this.currentView = 'default';
      } catch (e) {
        this.setError('An error occurred, please try again');
      }
      this.setLoading('');
      this.code = '';
    },
    next() {
      this.$emit('done')
    },
    change() {
      this.currentView = 'default'
    },
    getOccupation() {
      const rand = Math.floor(Math.random() * 10) + 1;
      return ['banker', 'doctor', 'lawyer', 'software developer', 'business owner', 'carpenter', 'nurse', 'laborer', 'administrative assistant', 'mechanic', 'journalist'][rand];
    }
  }
}
</script>
