<template>
  <div class="flex flex-col h-full overflow-hidden">
    <QuestionsStatus/>
    <div class="flex flex-col h-full overflow-y-auto pb-32 pt-8">
      <div v-if="!form" class="my-auto">
        <SectionHeader title="Looks like you have a twitter or Instagram account"/>
        <div class="flex flex-col gap-3">
          <div @click="form = true" class="border px-5 py-3 cursor-pointer rounded-lg flex justify-between items-center">
            Yes, provide social media handles
            <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
          </div>
          <div @click="$emit('done')" class="border px-5 py-3 cursor-pointer rounded-lg flex justify-between items-center">
            No skip
            <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
          </div>
        </div>
      </div>
      <div v-else class="my-auto">
        <SectionHeader title="Provide social media handles"/>
        <div class="mt-6">
          <ValidationObserver ref="observer">
            <ValidationProvider name="twitter" rules="required" v-slot="{ errors }">
              <AppInput placeholder="Twitter username" v-model="values.twitter" :errors="errors"/>
            </ValidationProvider>
            <ValidationProvider name="instagram" rules="required" v-slot="{ errors }">
              <AppInput placeholder="Instagram handle" v-model="values.instagram" class="mt-4" :errors="errors"/>
            </ValidationProvider>
            <div class="mt-8 flex items-center">
              <button @click="form = false" class="border border-gray-500 text-gray-700 px-3 py-1 mr-2 rounded-lg text-sm">
                <i class="fa fa-chevron-left mr-2"></i> Back
              </button>
              <AppButton @click="submit()">Continue</AppButton>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	import SectionHeader from "@/components/global/SectionHeader";
	import AppInput from "@/components/global/Input";
	import AppButton from "@/components/global/Button";
	import QuestionsStatus from "@/components/sections/QuestionsStatus";

	export default {
		name: "SocialHandles",
		components: { AppButton, AppInput, SectionHeader, QuestionsStatus },
		data() {
			return {
				form: false,
				values: { ...this.$store.state.social },
			};
		},
		created() {
			const { recently, twoWeeks, oneMonth, threeMonths } = this.$store.getters['lastApplied'];
			if (recently || twoWeeks || oneMonth || threeMonths) return this.$emit('done')
		},
		methods: {
			async submit() {
				if (!await this.$refs.observer.validate()) return;
				this.$store.commit('patch', { social: this.values });
				this.$emit('done');
			}
		}
	}
</script>
