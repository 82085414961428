<template>
  <div>
    <div class="flex w-32 h-32 rounded-full border relative">
      <img :src="image || '/assets/images/avatar.svg'" class="w-full h-full object-cover rounded-full" alt="">
      <label
          for="file"
          class="text-sm absolute top-1 right-0 w-8 h-8 bg-yellow-600 text-white bg-opacity-70 rounded-full bg-gray-100 flex items-center justify-center hover:bg-yellow-700 transition cursor-pointer"
      >
        <i class="fa fa-pen"></i>
      </label>
      <input type="file" id="file" hidden @change="loadImage" accept="image/png,image/jpeg,image/jpg"/>
    </div>
    <Modal v-if="cropper.visible" full>
      <div class="my-auto">
        <cropper
            class="h-72"
            :src="cropper.image"
            :stencil-props="{ aspectRatio: 1 }"
            @change="change"
        />
        <div class="flex justify-center mt-10">
          <AppButton :loading="uploading" @click="upload()" :disabled="uploading">Upload</AppButton>
          <button @click="reset()" :disabled="uploading" class="py-1 px-3 border border-red-500 text-red-500 rounded-lg ml-2">
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/global/Modal";
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import AppButton from "@/components/global/Button";

export default {
  name: "Avatar",
  components: { AppButton, Modal, Cropper },
  data() {
    return {
      cropper: {
        visible: false,
        image: null,
        canvas: null
      },
      uploading: false
    };
  },
  created() {
    // console.log(this.image);
    // this.handleDataUrl();
  },
  methods: {
    // async handleDataUrl() {
    //   if (this.image.startsWith('data:image/')) {
    //     const blob = this.dataURLtoBlob(this.image);
    //     await this.save(blob);
    //   }
    // },
    loadImage(event) {
      const input = event.target;
      if (input.files && input.files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.cropper.image = e.target.result;
          this.cropper.visible = true;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    change({ canvas }) {
      this.cropper.canvas = canvas
    },
    reset() {
      this.cropper.visible = false;
      this.cropper.image = null;
      this.cropper.canvas = null;
    },
    upload() {
      this.cropper.canvas.toBlob(async blob => await this.save(blob), 'image/jpeg');
    },
    async save(blob) {
      this.uploading = true;
      try {
        const fd = new FormData();
        fd.append('file', blob, 'avatar.jpeg');
        fd.append('token', this.$store.state.token);
        const { data } = await this.$http.post('/customer/upload/picture', fd, { headers: { 'Content-Type': 'multipart/form-data' } });
        if (data.status) {
          this.$store.commit('profile', { profile_image: data.data.filename })
          this.reset();
        }
      } catch (e) {
        console.log(e);
      }
      this.uploading = false;
    },
    // async dataUrlToFile(dataUrl, fileName) {
    //   const res = await fetch(dataUrl);
    //   const blob = await res.blob();
    //   return new File([blob], fileName, { type: 'image/jpeg' });
    // },
    // dataURLtoBlob(dataurl) {
    //   let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    //       bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    //   while (n--) {
    //     u8arr[n] = bstr.charCodeAt(n);
    //   }
    //   return new Blob([u8arr], { type: mime });
    // }
    // dataUrlToBlob(inputURI) {
    //   let binaryVal;
    //   const inputMIME = inputURI.split(',')[0].split(':')[1].split(';')[0];
    //   if (inputURI.split(',')[0].indexOf('base64') >= 0) binaryVal = atob(inputURI.split(',')[1]);
    //   else binaryVal = unescape(inputURI.split(',')[1]);
    //   const blobArray = [];
    //   for (let index = 0; index < binaryVal.length; index++) {
    //     blobArray.push(binaryVal.charCodeAt(index));
    //   }
    //   return new Blob([blobArray], { type: inputMIME });
    // }
  },
  computed: {
    image() {
      const { profile_image } = this.$store.state.profile;
      if (profile_image === 'https://res.cloudinary.com/du1mqyww8/image/upload/v1617623098/wnwad3hebiais8ulr5yy.png') return null
      else return profile_image;
    }
  }
}
</script>
