<template>
  <div class="h-full">
    <div class="flex justify-between">
      <span></span>
      <button
        @click="cancel()"
        class="py-1 px-3 border border-red-700 rounded-lg text-red-700 text-sm"
      >
        Cancel
      </button>
    </div>

    <div class="h-full flex flex-col">
      <div class="my-auto">
        <Loader
          v-if="loading"
          class="my-auto"
          text="Please wait.."
        />
        <template v-else>
          <Questions
            v-if="view === 'questions'"
            @done="pushView('offers')"
          />
          <Offers
            v-if="view === 'offers'"
            @done="pushView('signature')"
          />
          <Signature
            v-if="view === 'signature'"
            @done="pushView('card')"
          />
          <RepaymentCard
            v-if="view === 'card'"
            @done="next()"
          />
          <Modal v-if="modal === 'fcn-confirmation'">
            <ConfirmFrequentlyCalledNumbers @done="pushView('offers')" />
          </Modal>
          <Modal v-if="modal === 'offer-ready'">
            <OfferReady @done="pushView('offers')" />
          </Modal>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Questions from "@/components/sections/Questions";
import Modal from "@/components/global/Modal";
import Offers from "@/components/sections/Offers";
import Loader from "@/components/global/Loader";
import view from '@/mixins/view';
import OfferReady from "@/components/sections/OfferReady";
import Signature from "@/components/sections/Signature";
import RepaymentCard from "@/components/sections/RepaymentCard";
import ConfirmFrequentlyCalledNumbers from "@/components/sections/ConfirmFrequentlyCalledNumbers";

export default {
  name: "PostAnalysis",
  components: { ConfirmFrequentlyCalledNumbers, RepaymentCard, Signature, OfferReady, Loader, Offers, Modal, Questions },
  mixins: [view],
  data() {
    return {
      loading: false
    };
  },
  created() {
    const { stage } = this.$store.state;
    if (stage === 3) return this.pushView('signature');
    else if (stage > 3) return this.next();
    if (this.$store.getters['lastApplied'].recently && !stage) this.pushView('offers');
    else {
      this.$bus.$on('time-finished', () => {
        this.pushView('offers');
      });
      this.pushView('questions');
    }
  },
  methods: {
    next() {
      this.$emit('done');
    },
    cancel() {
      const r = confirm("Are you sure you want to cancel?");
      if (r === true) {
        parent.postMessage({ id: 'cclan-cancel' }, "*");
      }
    },
  }
};
</script>
